import React, {useEffect} from 'react';
import {LANDING_LINK} from "./links";

const RedirectToLanding = () => {
    useEffect(() => {
        window.location.href = LANDING_LINK;
    }, []);

    return null;
};

export default RedirectToLanding;