import React, {
    useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActionSheet, Modal } from 'antd-mobile';
import {
    Button, Confirm, Dropdown, Icon as IconS, Loader, Popup,
} from 'semantic-ui-react';
import styled from 'styled-components';

import {
    cardSelector,
    clearGridCard,
    defaultFormDataSelector,
    editCardRequest,
    editProgressSelector,
    errorSelector,
    getCardRequest,
    getDefaultFormData,
    getRegTcConfig,
    isUniqueNumberRequest,
    progressSelector,
    regTCRequest,
    setPaymentTermModal,
    settingsFormSelector,
} from '../../ducks/gridCard';
import {
    actionProgressSelector,
    actionsCardSelector,
    clearActions,
    getActionsRequest,
    invokeActionRequest,
    progressActionNameSelector,
} from '../../ducks/gridActions';
import {
    FREE_TRANSPORTS_GRID, ORDERS_GRID, REGISTRATION_TC_GRID, SHIPPINGS_GRID,
} from '../../constants/grids';
import ShippingCard from './components/shippingCard';
import ShippingCardMobile from './components/mobile/shippingCard';
import {
    GRID_CARD_LINK, LOGIN_LINK,
    TC_FORM_NO_LOGGED_LINK,
} from '../../router/links';
import { clearHistory, getHistoryRequest } from '../../ducks/history';
import { exportProgressSelector } from '../../ducks/autogrouping';
import { generateReportRequest, printLoaderSelector } from '../../ducks/reports';
import RegistrationTCCard from './components/regTransportCompanyCard';
import { isAuthSelector } from '../../ducks/login';
import { downloadAllDocuments, downloadFilesProgressSelector } from '../../ducks/documents';
import PhoneNumberModal from './components/shippingTabs/phoneNumberModal';
import PaymentTermModal from './components/shippingTabs/paymentTermModal';
import FreeTransportCard from './components/freeTransportCard';
import DeliveryTypeModal from './components/shippingTabs/deliveryTypeModal';
import { goBack } from '../../utils/urlParamsHelper';

const StyledDownloadButton = styled(Button)`
    && {
        font-size: 16px !important;
        font-weight: 600 !important;

        background: #ffffff;
        border: 1px solid #1C78D3;
        color: #222222;
        margin-bottom: 4px;
        height: 50px;
        display: flex !important;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }
`;

const StyledButton = styled(Button)`
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    height: 60px;
    background: rgb(46,105,164) !important;
    background: linear-gradient(0deg, rgba(46,105,164,1) 0%, rgba(28,120,211,1) 100%) !important;
`;

const MobileActions = ({
    actions, id, name, isRegTC, onClose, loadCard, printReport,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handler = useRef();

    const invokeAction = async (actionName) => {
        const isAccepted = await Modal.confirm({
            title: 'Подтвердить',
            content: `${t('Are you sure to complete')} "${t(actionName)}"?`,
            confirmText: 'Подтвердить',
            cancelText: 'Отменить',
        });

        if (isAccepted) {
            dispatch(
                invokeActionRequest({
                    isRegTC,
                    ids: [id],
                    name,
                    actionName,
                    redirectCallback: onClose,
                    callbackSuccess: () => {
                        if (actionName.toLowerCase().includes('delete')) {
                            onClose();
                        } else {
                            loadCard(true);
                        }
                    },
                }),
            );
        }

        handler.current?.close();
    };

    const mobileActions = (actions || [])
        .filter((action) => action.allowedFromForm)
        .map((action) => ({
            text: t(action.name),
            key: action.name,
            onClick: () => invokeAction(action.name),
        }));

    const downloadActions = [
        {
            text: t('genericReport'),
            key: 0,
            onClick: () => printReport(null, { value: 0 }),
        },
        {
            text: t('forwardingReport'),
            key: 1,
            onClick: () => printReport(null, { value: 1 }),
        }
    ];

    const handleActionsClick = () => {
        handler.current = ActionSheet.show({
            actions: mobileActions,
        });
    };

    const handleDownloadClick = () => {
        handler.current = ActionSheet.show({
            actions: downloadActions,
        });
    };


    return (
        <>
            <StyledDownloadButton
                fluid
                onClick={handleDownloadClick}
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_608_18341)">
                        <path d="M9.125 1.5C7.06502 1.5 5.38001 3.10783 5.2572 5.13649C5.23684 5.47275 4.99478 5.75418 4.66534 5.8246C3.14198 6.15023 2 7.50513 2 9.12502C2 10.9546 3.45609 12.4444 5.27231 12.4985C5.67766 12.5106 6 12.8426 6 13.2482V13.25C6 13.6642 5.66421 14 5.25 14C5.22283 14 5.196 13.9986 5.16958 13.9958C2.57224 13.8879 0.5 11.7486 0.5 9.12502C0.5 6.97563 1.89039 5.15244 3.82039 4.50337C4.23711 1.94907 6.45319 0 9.125 0C11.242 0 13.0717 1.22359 13.9481 3.00027L14 3.00002C17.0376 3.00002 19.5 5.46246 19.5 8.50002C19.5 11.3425 17.3443 13.6806 14.578 13.97C14.1661 14.013 13.7972 13.714 13.7541 13.3021C13.711 12.8901 14.01 12.5212 14.422 12.4781C16.4324 12.2678 18 10.5665 18 8.50002C18 6.29088 16.2091 4.50002 14 4.50002C13.8478 4.50002 13.6979 4.50849 13.5506 4.52492C13.2075 4.5632 12.8826 4.36231 12.7635 4.03827C12.2186 2.55569 10.7941 1.5 9.125 1.5Z" fill="#1C78D3"/>
                        <path d="M10 7C10.4142 7 10.75 7.33579 10.75 7.75V17.9393L12.9697 15.7197C13.2626 15.4268 13.7374 15.4268 14.0303 15.7197C14.3232 16.0126 14.3232 16.4874 14.0303 16.7803L11.4142 19.3964C10.6332 20.1775 9.36684 20.1775 8.58579 19.3964L5.96967 16.7803C5.67678 16.4874 5.67678 16.0126 5.96967 15.7197C6.26256 15.4268 6.73744 15.4268 7.03033 15.7197L9.25 17.9393V7.75C9.25 7.33579 9.58579 7 10 7Z" fill="#1C78D3"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_608_18341">
                            <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

                {t('printReport')}
            </StyledDownloadButton>

            {actions?.length ? (
                <StyledButton
                    fluid
                    content="Действия с перевозкой"
                    onClick={handleActionsClick}
                />
            ) : null}
        </>
    );
};

const Card = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location = {} } = props;
    const { state: locationState = {} } = location;
    const isRegTC = match.path === TC_FORM_NO_LOGGED_LINK;
    const { params = {} } = !isRegTC ? match : { params: { name: REGISTRATION_TC_GRID, id: match.params.id } };
    const { name, id } = params;
    const scrollTo = location.hash.replace('#', '');

    useEffect(() => {
        isRegTC && dispatch(getRegTcConfig());
    }, []);

    const [form, setForm] = useState({});
    const [notChangeForm, setNotChangeForm] = useState(true);
    const [confirmation, setConfirmation] = useState({ open: false });

    const nameNumber = (name, form) => {
        switch (name) {
        case REGISTRATION_TC_GRID:
            return form.number;
        case ORDERS_GRID:
            return form.orderNumber;
        default:
            return form.shippingNumber;
        }
    };

    const title = useMemo(
        () => (id
            ? t(`edit_${name}`, {
                number: nameNumber(name, form),
                status: t(form.status || form.state),
                interpolation: { escapeValue: false },
            })
            : t(`new_${name}`)),
        [name, id, form],
    );

    const card = useSelector((state) => cardSelector(state));
    const settings = useSelector((state) => settingsFormSelector(state, card.status || card.state));
    const error = useSelector((state) => errorSelector(state));
    const exportLoader = useSelector((state) => exportProgressSelector(state));
    const printLoader = useSelector((state) => printLoaderSelector(state));
    const downloadFilesProgress = useSelector((state) => downloadFilesProgressSelector(state));
    const isAuth = useSelector((state) => isAuthSelector(state));

    const defaultForm = useSelector((state) => defaultFormDataSelector(state));
    const isCreate = ([FREE_TRANSPORTS_GRID].includes(name) && !id);

    useEffect(() => {
        (isCreate && defaultForm) && setForm((form) => ({ ...form, ...defaultForm }));
    }, [defaultForm]);

    useEffect(() => {
        if (locationState.form) {
            setForm(locationState.form);
        }

        if (locationState.lookup && locationState.name) {
            onChangeForm(null, {
                name: locationState.name,
                value: locationState.lookup,
            });
            if (locationState.autocompleteItems) {
                locationState.autocompleteItems.forEach((value) => {
                    onChangeForm(null, {
                        name: value.name,
                        value: locationState.lookup[value.nameValue],
                    });
                    if (value.dependentValueName && locationState.lookup[value.nameValue] === value.dependentValue) {
                        onChangeForm(null, {
                            name: value.dependentValueName,
                            value: value.dependentValue,
                        });
                    }
                });
            }
            setNotChangeForm(false);
        }
    }, [location]);

    useEffect(() => {
        !locationState.form && setForm({
            ...card,
        });
    }, [card]);

    useEffect(() => {
        dispatch(clearActions());
        id && loadCard();
        isCreate && dispatch(getDefaultFormData(name));

        return () => {
            dispatch(clearHistory());
            dispatch(clearGridCard());
        };
    }, []);

    useEffect(
        () => {
            if (notChangeForm) {
                Object.keys(form).forEach((key) => {
                    if (form[key] !== card[key]) {
                        setNotChangeForm(false);
                    }
                });
            }
        },
        [form],
    );

    const dropLocationState = () => {
        history.push({
            state: {
                autocompleteItems: null,
                name: null,
                form: null,
            },
        });
    };

    const loadCard = (notChange = false) => {
        id && dispatch(
            getCardRequest({
                isRegTC,
                name,
                id,
                callbackSuccess: () => {
                    setNotChangeForm(!notChange ? !locationState.form : true);
                },
                redirectCallback: onClose,
            }),
        );
        id && dispatch(
            getActionsRequest({
                name,
                ids: [id],
                isCard: true,
                isRegTC,
            }),
        );
        id && !isRegTC && getHistory();
    };

    const getHistory = () => {
        dispatch(getHistoryRequest({ id, isRegTC }));
    };

    const onClose = () => {
        const { state = {} } = location;
        const { pathname: pn, gridLocation } = state;
        const { path, params } = match;
        const pathname = pn || ((path && params) ? path.replace(':name', params.name).replace('/:id', '') : null);
        (pathname && isAuth) ? history.replace({
            pathname,
            state: {
                ...state,
                pathname: gridLocation,
            },
        }) : (
            isAuth
                ? goBack(history, location, id)
                : history.replace(LOGIN_LINK)
        );
    };

    const handleClose = (isConfirm) => {
        if (!isConfirm || notChangeForm) {
            onClose();
        } else {
            showConfirmation(
                t('confirm_close_dictionary'),
                () => {
                    closeConfirmation();
                    onClose();
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const onChangeForm = useCallback((e, { name, value }) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    const saveOrEditForm = (callbackFun) => {
        locationState.form && dropLocationState();
        const formData = {
            name,
            params: form,
            callbackSuccess: callbackFun || ((actionTryToRun) => {
                if (form.id) {
                    if (actionTryToRun) {
                        invokeAction(actionTryToRun);
                    } else {
                        history.replace(location.pathname);
                        loadCard();
                    }
                } else {
                    handleClose();
                }
            }),
        };
        dispatch(isRegTC ? regTCRequest(formData) : editCardRequest(formData));
    };

    const handleSave = () => {
        if (name === ORDERS_GRID && !form.id) {
            handleUniquenessCheck(saveOrEditForm);
        } else {
            saveOrEditForm();
        }
    };

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const invokeAction = (actionName) => {
        showConfirmation(
            `${t('Are you sure to complete')} "${t(actionName)}"?`,
            () => {
                closeConfirmation();
                dispatch(
                    invokeActionRequest({
                        isRegTC,
                        ids: [id],
                        name,
                        actionName,
                        redirectCallback: onClose,
                        callbackSuccess: () => {
                            if (actionName.toLowerCase().includes('delete')) {
                                onClose();
                            } else {
                                loadCard(true);
                            }
                        },
                    }),
                );
            },
            closeConfirmation,
        );
    };

    const handleUniquenessCheck = (callbackFunc) => {
        dispatch(
            isUniqueNumberRequest({
                number: form.orderNumber,
                fieldName: 'orderNumber',
                errorText: t('number_already_exists'),
                callbackSuccess: callbackFunc,
            }),
        );
    };

    const loading = useSelector((state) => progressSelector(state));
    const editLoading = useSelector((state) => editProgressSelector(state));
    const actions = useSelector((state) => actionsCardSelector(state));
    const progressActionName = useSelector((state) => progressActionNameSelector(state));
    const actionProgress = useSelector(actionProgressSelector);
    const disableSave = (name === REGISTRATION_TC_GRID && !form.isAcceptPersonalDataPolicy) || ((!form.isSaveButtonForceEnabled) && (progressActionName || notChangeForm || !(form.isSaveButtonEnabled || isRegTC || isCreate)));

    const getActionsFooter = useCallback(
        () => (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={disableSave}
                    loading={editLoading}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </>
        ),
        [form, disableSave, editLoading, name],
    );

    const goToCard = (gridName, cardId) => {
        const { state } = location;
        history.replace({
            pathname: GRID_CARD_LINK.replace(':name', gridName).replace(':id', cardId),
            state: {
                ...state,
                pathname: history.location.pathname,
                gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
            },
        });
    };

    const getActionsHeader = useCallback(
        () => (
            <div
                className="grid-card-header"
            >
                {
                    name === SHIPPINGS_GRID && form.isReducePaymentButtonEnable
                    && (
                        <Button
                            onClick={() => dispatch(setPaymentTermModal({
                                open: true,
                                ids: [form.id],
                            }))}
                        >
                            {t('reducePaymentTerms')}
                        </Button>
                    )
                }

                {name === ORDERS_GRID && form.shippingId ? (
                    <div className="link-cell" onClick={() => goToCard(SHIPPINGS_GRID, form.shippingId)}>
                        {t('open_shipping', { number: form.shippingNumber })}
                    </div>
                ) : null}
                {![REGISTRATION_TC_GRID, FREE_TRANSPORTS_GRID].includes(name)
                        && (
                            <Dropdown
                                className="grid-print-btn"
                                pointing="top right"
                                trigger={(
                                    <Popup
                                        content={t('printReport')}
                                        position="bottom right"
                                        trigger={(
                                            <Button
                                                icon
                                                loading={printLoader}
                                            >
                                                <IconS name="print" />
                                            </Button>
                                        )}
                                    />
                                )}
                                value={null}
                                selectOnBlur={false}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={printReport} key={0} value={0}>{t('genericReport')}</Dropdown.Item>
                                    <Dropdown.Item onClick={printReport} key={1} value={1}>{t('forwardingReport')}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                {name === REGISTRATION_TC_GRID && !isRegTC
                    && (
                        <Popup
                            content={t('download-docs')}
                            position="bottom right"
                            trigger={(
                                <Button
                                    icon="download"
                                    loading={downloadFilesProgress}
                                    onClick={downloadFiles}
                                    disabled={downloadFilesProgress || !form.isAllDocsDownload}
                                />
                            )}
                        />
                    )}

                {name === SHIPPINGS_GRID && form.orders && form.orders.length ? (
                    <Dropdown
                        text={t('orders')}
                        pointing="top right"
                        className="dropdown-blue"
                        scrolling
                    >
                        <Dropdown.Menu>
                            {form.orders.map((order) => (
                                <Dropdown.Item
                                    className="link-cell"
                                    key={order.id}
                                    text={order.orderNumber}
                                    onClick={() => {
                                        goToCard(ORDERS_GRID, order.id);
                                    }}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : null}

                {![FREE_TRANSPORTS_GRID].includes(name) && (
                    <Dropdown
                        icon="ellipsis horizontal"
                        floating
                        button
                        pointing="top right"
                        className="icon"
                        scrolling
                    >
                        <Dropdown.Menu>
                            {actions
                            && actions.filter((item) => item.allowedFromForm).map((action) => (
                                <Dropdown.Item
                                    disabled={actionProgress}
                                    key={action.name}
                                    text={t(action.name)}
                                    label={{
                                        color: action.color,
                                        empty: true,
                                        circular: true,
                                    }}
                                    onClick={() => invokeAction(action.name)}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
        ),
        [form, actions, name, printLoader, downloadFilesProgress],
    );

    const printReport = (e, { value }) => {
        dispatch(generateReportRequest({
            gridName: name,
            params: {
                type: value,
                isGrid: false,
                ids: [id],
            },
        }));
    };

    const printReportMobile = (e, { value }) => {
        dispatch(generateReportRequest({
            gridName: name,
            isMobile: true,
            params: {
                type: value,
                isGrid: false,
                ids: [id],
            },
        }));
    };

    const downloadFiles = () => {
        dispatch(downloadAllDocuments({
            id: form.id,
        }));
    };

    const typeCard = (name) => {
        switch (name) {
        case SHIPPINGS_GRID:
            if (props.isMobile) {
                return (
                    <ShippingCardMobile
                        {...props}
                        renderMobileActions={() => (
                            <MobileActions
                                actions={actions}
                                id={id}
                                name={name}
                                isRegTC={isRegTC}
                                onClose={onClose}
                                loadCard={loadCard}
                                printReport={printReportMobile}
                            />
                        )}
                        title={title}
                        id={id}
                        name={name}
                        form={form}
                        load={loadCard}
                        updateHistory={getHistory}
                        loading={loading}
                        settings={settings}
                        error={error}
                        onClose={handleClose}
                        onChangeForm={onChangeForm}
                        actionsFooter={getActionsFooter}
                        actionsHeader={getActionsHeader}
                        actions={actions}
                        saveForm={saveOrEditForm}
                        scrollTo={scrollTo}
                    />
                );
            }

            return (
                <ShippingCard
                    {...props}
                    title={title}
                    id={id}
                    name={name}
                    form={form}
                    load={loadCard}
                    updateHistory={getHistory}
                    loading={loading}
                    settings={settings}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    saveForm={saveOrEditForm}
                    scrollTo={scrollTo}
                />
            );
        case REGISTRATION_TC_GRID:
            return (
                <RegistrationTCCard
                    {...props}
                    title={title}
                    id={id}
                    name={name}
                    form={form}
                    load={loadCard}
                    updateHistory={getHistory}
                    loading={loading}
                    settings={settings}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    saveForm={saveOrEditForm}
                    isRegTC={isRegTC}
                    scrollTo={scrollTo}
                />
            );
        case FREE_TRANSPORTS_GRID:
            return (
                <FreeTransportCard
                    {...props}
                    title={title}
                    id={id}
                    name={name}
                    form={form}
                    load={loadCard}
                    updateHistory={getHistory}
                    loading={loading}
                    settings={settings}
                    error={error}
                    onClose={handleClose}
                    onChangeForm={onChangeForm}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    saveForm={saveOrEditForm}
                    isRegTC={isRegTC}
                    scrollTo={scrollTo}
                />
            );
        default: return null;
        }
    };

    return (
        <React.Fragment key={name}>
            {loading
                ? (
                    <Loader active size="huge" className="table-loader">
                        Loading
                    </Loader>
                )
                : typeCard(name)}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <PhoneNumberModal callbackSuccess={() => loadCard(true)} />
            <DeliveryTypeModal callbackSuccess={() => loadCard(true)} />
            <PaymentTermModal callbackSuccess={() => loadCard(true)} name={name} />
        </React.Fragment>
    );
};

export default Card;
