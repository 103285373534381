import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {Button, Confirm, Grid, Loader, Popup, Table} from 'semantic-ui-react';
import InfiniteScrollTable from '../InfiniteScrollTable';
import { debounce } from 'throttle-debounce';
import { PAGE_SIZE } from '../../constants/settings';
import Search from '../Search';
import './style.scss';
import HeaderCellComponent from './components/header-cell';
import BodyCellComponent from './components/body-cell';
import Icon from '../CustomIcon';
import CustomCheckbox from "../BaseComponents/CustomCheckbox";
import ActionsTableCell from "../../containers/customDictionary/actionsTableCell";
import Documents from "../../containers/customGrid/components/shared/documents";
import {getAllIdsRequest} from "../../ducks/dictionaryView";
import {connect} from "react-redux";

const ModalComponent = ({ element, props, children }) => {
    if (!element) {
        return <>{children}</>;
    }
    return React.cloneElement(element(props), props, children);
};

class TableInfo extends Component {
    constructor(props) {
        super(props);

        const { storageFilterItem, storageSortItem } = props;
        window.scrollTo(0, 0);

        this.state = {
            page: 1,
            search: '',
            filters: storageFilterItem
                ? JSON.parse(localStorage.getItem(storageFilterItem)) || {}
                : {},
            sort: storageSortItem ? JSON.parse(localStorage.getItem(storageSortItem)) || {} : {},
            selectedRows: [],
            confirmation: {open: false}
        };
    }

    componentDidMount() {
        const { location, history } = this.props;
        const { state } = location;

        if (state) {
            this.setState(
                {
                    search: state.filter && state.filter.search,
                    page: state.skip && state.take ? parseInt(state.skip / state.take) : 1,
                },
                () => {
                    history.replace(location.pathname, null);
                    this.load(false, true, () => {
                        if (state.scroll) this.container.scrollTop = state.scroll;
                    });
                },
            );
        } else {
            this.load();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.triggerLoad !== this.props.triggerLoad) {
            this.props.topBlockOff ? this.setState({
                page: 1
            }, () => this.load(null, true, () => this.container.scrollTop = 0))
            : this.load(null, true);
        }
    }

    mapData = (isConcat, isReload, scrollTop) => {
        const { search, page, filters, sort } = this.state;
        const { name } = this.props;

        const params = {
            filter: {
                filter: {
                    ...filters,
                    search,
                },
                take: isReload ? page * PAGE_SIZE : PAGE_SIZE,
                skip: isReload ? 0 : (page - 1) * PAGE_SIZE,
                sort,
            },
            isConcat,
            name,
            scrollTop
        };

        return params;
    };

    load = (isConcat, isReload, scrollTop) => {
        const { loadList } = this.props;
        loadList(this.mapData(isConcat, isReload, scrollTop));
    };

    nextPage = () => {
        const { totalCount, list = [] } = this.props;

        if (list.length < totalCount) {
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                }),
                () => this.load(true),
            );
        }
    };

    changeFullTextFilter = (e, { value }) => {
        this.setState({ search: value, page: 1, selectedRows: [] }, this.load);
    };

    setFilter = (e, { name, value }) => {
        this.setState(prevState => {
            let { filters } = prevState;
            if (value !== null && value !== undefined && value !== '') {
                filters = { ...filters, [name]: value };
            } else if (filters[name] !== null && filters[name] !== undefined) {
                filters = Object.assign({}, filters);
                delete filters[name];
            }
            return {
                ...prevState,
                filters,
                page: 1,
            };
        }, this.debounceSetFilterApiAndLoadList);
        this.setState({selectedRows: []});
    };

    setSort = sort => {
        const { storageSortItem } = this.props;

        storageSortItem && localStorage.setItem(storageSortItem, JSON.stringify(sort));

        this.setState(
            {
                sort,
                page: 1,
            },
            this.loadAndResetContainerScroll,
        );
    };

    setFilterApiAndLoadList = () => {
        const { filters } = this.state;

        const filtersJson = JSON.stringify(filters);
        const { storageFilterItem } = this.props;

        storageFilterItem && localStorage.setItem(storageFilterItem, filtersJson);

        this.loadAndResetContainerScroll();
    };

    debounceSetFilterApiAndLoadList = debounce(300, this.setFilterApiAndLoadList);

    loadAndResetContainerScroll = () => {
        this.load();
        if (this.container && this.container.scrollTop) {
            this.container.scrollTop = 0;
        }
    };

    clearFilter = () => {
        const { storageFilterItem } = this.props;
        localStorage.setItem(storageFilterItem, JSON.stringify({}));
        this.setState(
            {
                filters: {},
                page: 1,
            },
            this.loadAndResetContainerScroll,
        );
    };

    setSelectedAll = () => {
        const {selectedRows} = this.state;
        const {name, getAllIds, totalCount} = this.props;
        if (totalCount !== selectedRows.length) {
            getAllIds({
                filter: this.mapData().filter,
                name,
                callbackSuccess: (ids) => {
                    this.setState({selectedRows: ids})
                }
            })
        } else {
            this.setState({selectedRows: []})
        }
    };

    setSelectedRow = (id) => {
        this.setState(prevState => {
            return {selectedRows: !prevState.selectedRows.includes(id) ? [...prevState.selectedRows, id] : prevState.selectedRows.filter(rowId => rowId !== id)}
        })
    };

    headerRowComponent = () => {
        const {sort} = this.state;
        const {name, t, checkboxRows} = this.props;

        return (
            <Table.Row>
                {checkboxRows && <Table.HeaderCell className={'column-facet__first report-with-checkbox'}>
                    <CustomCheckbox
                        checked={this.props.list.length && this.props.totalCount === this.state.selectedRows.length}
                        multi
                        onChange={this.setSelectedAll}
                    />
                </Table.HeaderCell>}

                {this.props.headerRow &&
                    this.props.headerRow.map((row, index) => (
                        <HeaderCellComponent
                            key={row.name}
                            row={row}
                            index={index}
                            filters={this.mapData()}
                            sort={sort}
                            gridName={name}
                            setSort={this.setSort}
                            setFilter={this.setFilter}
                        />
                    ))}
                {this.props.isShowActions ? <Table.HeaderCell textAlign='center'>{t('actions')}</Table.HeaderCell> : null}
            </Table.Row>
        );
    };

    importFromExcel = () => {
        this.fileUploader && this.fileUploader.click();
    };

    exportToExcel = () => {
        this.props.exportToExcel && this.props.exportToExcel(this.mapData());
    };

    onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);
        this.props.importFromExcel(data, () => this.load(false, true));

        e.target.value = null;
    };

    handleToggleIsActive = (itemID, checked, name) => {
        this.props.toggleIsActive(itemID, checked, this.load, name);
    };

    handleRowClick = (e, id) => {
        const { history, cardLink, name } = this.props;

        if (!cardLink) {
            e.stopPropagation();
        } else {
            history.push({
                pathname: cardLink.replace(':name', name).replace(':id', id),
                state: {
                    ...this.mapData().filter,
                    pathname: history.location.pathname,
                    scroll: this.container.scrollTop,
                },
            });
        }
    };

    deleteRows = (id) => {
        const {t, name, deleteRows} = this.props;
        this.setState({
            confirmation: {
                open: true,
                onConfirm: () => {
                    deleteRows({
                        ids: id ? [id] : [...this.state.selectedRows],
                        name: name,
                        callbackSuccess: () => {
                            this.setState({confirmation: {open: false}, selectedRows: []});
                            this.load(false, true)
                        }
                    })
                },
                onCancel: () => this.setState({confirmation: {open: false}}),
                content: t(`Delete dictionary ${id ? 'entry' : 'entries'}`, {name: t(name)}),
            }
        });
    };

    toTop = () => {
        if (this.container) this.container.scrollTop = 0
    };

    uploadPolicyBtn = (id) => {
        return (this.props.name === 'transportCompanies' && id) && <Documents
            id={id}
            isBtn
            btnText={'uploadPolicy'}
            cardId={id}
            gridName={'transportCompanies'}
            isEditPermissions={true}
            updateHistory={() => this.load(false, true)}
        />
    }

    render() {
        const {
            headerRow,
            className,
            list = [],
            isShowActions,
            actions,
            loading,
            customRowComponent,
            newLink,
            t,
            name,
            modalCard,
            isDeleteBtn,
            isImportBtn,
            isExportBtn,
            importLoader,
            exportLoader,
            totalCount,
            history,
            newOnClick,
            checkboxRows,
            isModal = false,
            topBlockOff = false,
            isNewStyle = false,
            editRows,
            editRow,
            transportCompanyUserId,
            disabledFunc
        } = this.props;

        const { search, filters, confirmation, selectedRows } = this.state;

        const dictionariesWithSpecialColumns = ['fMContacts'];
        const columnsWithValueAll = ['transportCompanyId', 'transportCompanyName'];

        return (
            <div className={className}>
                {!isModal && <Loader active={loading && !list.length} size="huge" className="table-loader">
                    Loading
                </Loader>}
                {!topBlockOff && <Grid className={`table-header-menu ${isModal ? 'modal-header' : ''}`}>
                    <Grid.Row>
                        <Grid.Column width={isModal ? 11 : 5} verticalAlign="middle">
                            <span className="table-header-menu_title">{t(name)}</span>
                            <span className="records-counter">
                                {t('totalCount', {count: totalCount})}
                            </span>
                        </Grid.Column>
                        {!isModal && <Grid.Column width={11} textAlign="right">
                            {this.uploadPolicyBtn(transportCompanyUserId)}
                            {newLink || newOnClick ? (
                                <Popup
                                    content={t('add_record')}
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon="add"
                                            onClick={newLink ? () => {
                                                history.push({
                                                    pathname: newLink.replace(':name', name),
                                                    state: {
                                                        ...this.mapData().search,
                                                        pathname: history.location.pathname,
                                                    },
                                                });
                                            } : () => {
                                                isNewStyle && this.toTop();
                                                newOnClick();
                                            }}
                                        />
                                    }
                                />
                            ) : null}
                            {isImportBtn ? (
                                <Popup
                                    content={t('importFromExcel')}
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon="upload"
                                            loading={importLoader}
                                            onClick={this.importFromExcel}
                                        />
                                    }
                                />
                            ) : null}
                            {isExportBtn ? (
                                <Popup
                                    content={
                                        t('exportToExcel') // todo
                                    }
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon="download"
                                            loading={exportLoader}
                                            onClick={this.exportToExcel}
                                        />
                                    }
                                />
                            ) : null}
                            {checkboxRows && isDeleteBtn ? (
                                <Popup
                                    content={t('delete')}
                                    position="bottom right"
                                    trigger={
                                        <Button
                                            icon="trash alternate"
                                            disabled={!selectedRows.length}
                                            onClick={() => this.deleteRows()}
                                        />
                                    }
                                />
                            ) : null}
                            <Popup
                                content={t('reset_filters')}
                                position="bottom right"
                                trigger={
                                    <Button
                                        icon
                                        className={`clear-filter-btn`}
                                        onClick={this.clearFilter}
                                        disabled={!Object.keys(filters).length}
                                    >
                                        <Icon name="clear-filter"/>
                                    </Button>
                                }
                            />
                            <Search
                                value={search}
                                className="search-input"
                                onChange={this.changeFullTextFilter}
                            />
                        </Grid.Column>}
                    </Grid.Row>
                    <input
                        type="file"
                        ref={instance => {
                            this.fileUploader = instance;
                        }}
                        style={{display: 'none'}}
                        onChange={this.onFilePicked}
                    />
                </Grid>}
                <div
                    className={`scroll-table-container`}
                    ref={instance => {
                        this.container = instance;
                    }}
                >
                    <InfiniteScrollTable
                        className="grid-table table-info"
                        onBottomVisible={this.nextPage}
                        context={this.container}
                        headerRow={this.headerRowComponent()}
                    >
                        <Table.Body>
                            {customRowComponent
                                ? customRowComponent
                                : list &&
                                list.map((row, i) => (
                                    <ModalComponent
                                        element={modalCard}
                                        props={{row, loadList: this.load, name}}
                                        key={`modal_${row.id}`}
                                    >
                                        <Table.Row
                                            key={row.id}
                                            onClick={e => this.handleRowClick(e, row.id)}
                                        >
                                            {
                                                checkboxRows && <Table.Cell onClick={e => e.stopPropagation()}
                                                                            className={'column-facet__first report-with-checkbox'}>
                                                    <CustomCheckbox
                                                        checked={this.state.selectedRows.includes(row.id)}
                                                        onChange={() => this.setSelectedRow(row.id)}
                                                    />
                                                </Table.Cell>
                                            }
                                            {(isNewStyle && (editRows || []).includes(row.id))
                                                ? editRow(row, (r) => <ActionsTableCell
                                                    actions={actions}
                                                    load={this.load}
                                                    deleteRow={this.deleteRows}
                                                    row={r}
                                                    isEdit
                                                    toTop={this.toTop}
                                                />)
                                                : headerRow.map((column, index) => (
                                                    <BodyCellComponent
                                                        key={`cell_${row.id}_${column.name}`}
                                                        column={{
                                                            ...column,
                                                            isDisabled: disabledFunc ? disabledFunc(row) : column.isDisabled
                                                        }}
                                                        value={
                                                            (dictionariesWithSpecialColumns.includes(name) && columnsWithValueAll.includes(column.name) && !row[column.name].length) ? [{name: t('All')}] :
                                                                (row[column.name] &&
                                                                typeof row[column.name] === 'object' &&
                                                                !Array.isArray(row[column.name])
                                                                    ? row[column.name].value
                                                                    : row[column.name])
                                                        }
                                                        valueText={
                                                            row[column.name] &&
                                                            typeof row[column.name] === 'object' &&
                                                            !Array.isArray(row[column.name])
                                                                ? row[column.name].name
                                                                : null
                                                        }
                                                        id={row.id}
                                                        toggleIsActive={this.handleToggleIsActive}
                                                        indexRow={i}
                                                        indexColumn={index}
                                                        t={t}
                                                    />
                                                ))}
                                            {isShowActions && !(isNewStyle && (editRows || []).includes(row.id)) ? <ActionsTableCell
                                                actions={actions}
                                                load={this.load}
                                                deleteRow={this.deleteRows}
                                                row={row}
                                                toTop={this.toTop}
                                            /> : null}
                                        </Table.Row>
                                    </ModalComponent>
                                ))}
                            <div className="table-bottom-loader">
                                {!isModal && <Loader active={loading && list.length}/>}
                            </div>
                        </Table.Body>
                    </InfiniteScrollTable>
                </div>
                <Confirm
                    dimmer="blurring"
                    open={confirmation.open}
                    onCancel={confirmation.onCancel}
                    cancelButton={t('cancelConfirm')}
                    confirmButton={t('Yes')}
                    onConfirm={confirmation.onConfirm}
                    content={confirmation.content}
                />
            </div>
        );
    }
}

TableInfo.defaultProps = {
    loadList: () => {},
};

const mapStateToProps = () => {

}
const mapDispatchToProps = dispatch => {
    return {
        getAllIds: params => {
            dispatch(getAllIdsRequest(params));
        },
    };
};

export default withTranslation()(
    withRouter(connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TableInfo))
);

