import { ConfigProvider, SafeArea } from 'antd-mobile';

import ruRU from 'antd-mobile/es/locales/ru-RU';

import { Header } from '../components';
import MainRouteMobile from '../index';

const MobileApp = ({ isAuth }) => (
    <ConfigProvider locale={ruRU}>
        <SafeArea position="top" />

        {isAuth ? <Header /> : null}

        <MainRouteMobile />

        <SafeArea position="bottom" />
    </ConfigProvider>
);

export default MobileApp;