import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import RequiredFieldsModal from "../../components/RequiredFieldsModal";
import {EMAIL_TYPE} from "../../constants/columnTypes";
import {Button} from "semantic-ui-react";
import {progressChangePasswordSelector, recoverPassword} from "../../ducks/profile";

const RecoverPasswordModal = ({open, onClose: close}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const loading = useSelector(state => progressChangePasswordSelector(state));

    const save = (data) => {
        dispatch(recoverPassword({...data, close}));
    };

    const onClose = () => {
        close && close();
    }

    return (
        <RequiredFieldsModal
            error={{}}
            open={open}
            id={null}
            onClose={onClose}
            title={t('passwordRecovery')}
            fields={[
                {
                    isRequired: true,
                    name: "login",
                    text: "enterYourLogin",
                    type: EMAIL_TYPE
                }
            ]}
            saveRequest={save}
            saveBtnName={t('ok')}
            loading={loading}
            btns={[
                <Button onClick={onClose}>
                    {t('CancelButton')}
                </Button>
            ]}
        />
    );
};

export default RecoverPasswordModal;