export const driverFieldsWithMask = {
    passportNumber: {
        nameFieldFrom: 'documents',
        nameParamFrom: 'passportMask'
    },
    driverLicenseNumber: {
        nameFieldFrom: 'documents',
        nameParamFrom: 'driverLicenseMask'
    },
    passportIssuerCode: {
        nameFieldFrom: 'documents',
        nameParamFrom: 'departmentCodePassportMask'
    }
}

export const requiredSettings = {
    passportIssuerCode: {
        nameFieldFrom: 'documents',
        values: ['russianFederation']
    }
}