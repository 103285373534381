import React, {useEffect} from 'react';
import EmployeesContacts from "./employeesContacts";
import {useTranslation} from "react-i18next";
import {Form} from "semantic-ui-react";
import {getLookupRequest, listFromSelectSelector} from "../../../../ducks/lookup";
import {useDispatch, useSelector} from "react-redux";

const CompanyContacts = ({form, name, onChange, disabled}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const source = 'position';

    const data = useSelector(state =>
        listFromSelectSelector(state, source, t, null, true),
    );
    const rtcEmployees = data.filter(d =>
        [
            "logist",
            "director",
            "manager",
            "sundaySpecialist",
            "economistAccountant"
        ].includes(d.value));

    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
            }),
        );
    }, []);

    const onChangeValue = (values, isDelete) => {
        const val = (form[name] || []).filter(v => !values.map(vl => vl.id).includes(v.id));

        onChange(null, {
            name,
            value: isDelete ? val : [...val, ...(values || [])]
        })
    }

    const allContacts = (form[name] || []).map(c => ({...c, id: c.id || Date.now()}));

    const contacts = (rtcEmployee) => allContacts.filter(contact => (contact.position || {}).value === rtcEmployee.value);

    return (
        <Form className='company-contacts'>
            <Form.Field>
                {
                    rtcEmployees.map(rtcEmployee => {
                        return <EmployeesContacts
                            disabled={disabled}
                            key={rtcEmployee.value}
                            onChange={onChangeValue}
                            value={rtcEmployee}
                            label={rtcEmployee.name}
                            contacts={contacts(rtcEmployee)}
                        />
                    })
                }
            </Form.Field>
        </Form>

    );
};

export default CompanyContacts;