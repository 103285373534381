import React, {useCallback} from 'react';
import Text from "./Text";
import {useDispatch, useSelector} from "react-redux";
import {daDataListSelector, getDaDataRequest} from "../../ducks/lookup";
import qs from "qs";
import {debounce} from "throttle-debounce";

const TextWithDataList = ({onChange, source, paramName = 'value', nameParamFromDataList = 'name', ...props}) => {
    const dispatch = useDispatch();
    const list = useSelector(state => daDataListSelector(state, props.name, nameParamFromDataList));

    const getList = (value) => {
        dispatch(getDaDataRequest({
            source,
            name: props.name,
            params: qs.stringify({
                [paramName]: value,
                name: nameParamFromDataList
            })
        }))
    };

    const getListDebounce = useCallback(debounce(300, getList), []);

    const onChangeValue = (e, {name, value}) => {
        getListDebounce(value)
        onChange(e, {name, value});
    }

    return (
        <Text
            {...props}
            onChange={onChangeValue}
            datalist={list}
        />
    );
};

export default TextWithDataList;