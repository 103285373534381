import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableInfo from '../../components/TableInfo';
import { usersColumns } from '../../constants/usersColumns';
import {
    getUsersRequest,
    progressSelector,
    toggleUserActiveRequest,
    totalCountSelector,
    usersListSelector,
} from '../../ducks/users';
import { withTranslation } from 'react-i18next';
import {
    NEW_USER_LINK,
    USER_LINK,
} from '../../router/links';
import {exportToExcelRequest} from "../../ducks/dictionaryView";
import {roleSelector} from "../../ducks/profile";
import {ADMIN_ROLE_NAME} from "../../constants/rolesColumns";

export class UsersList extends Component {
    handleToggleIsActive = (itemID, checked, load) => {
        const { toggleActive } = this.props;
        toggleActive({
            id: itemID,
            active: checked,
            callbackSuccess: () => {
                load(false, true);
            },
        });
    };

    handleExportToExcel = filter => {
        const { exportFromExcel } = this.props;
        exportFromExcel({
            name: 'users',
            filter,
        });
    };

    disabledFunc = (row) => {
        return row.role === ADMIN_ROLE_NAME && this.props.role !== ADMIN_ROLE_NAME
    }

    render() {
        const { list, loadList, totalCount, loading, t } = this.props;

        return (
            <TableInfo
                isExportBtn
                exportToExcel={this.handleExportToExcel}
                headerRow={usersColumns}
                name="users"
                loading={loading}
                className="wider ui container container-margin-top-bottom"
                list={list}
                storageSortItem={`usersSort`}
                storageFilterItem={`usersFilters`}
                toggleIsActive={this.handleToggleIsActive}
                totalCount={totalCount}
                loadList={loadList}
                newLink={NEW_USER_LINK}
                cardLink={USER_LINK}
                disabledFunc={this.disabledFunc}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        list: usersListSelector(state),
        role: roleSelector(state),
        totalCount: totalCountSelector(state),
        loading: progressSelector(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadList: params => {
            dispatch(getUsersRequest(params));
        },
        toggleActive: params => {
            dispatch(toggleUserActiveRequest(params));
        },
        exportFromExcel: params => {
            dispatch(exportToExcelRequest(params));
        },
    };
};

export default withTranslation()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UsersList),
);
