import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabPane, MenuItem, Label } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  & > .ui.secondary.menu {
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    height: 48px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > .item.active {
        background: #1C78D3 !important;
    }

    & > .item {
      font-size: 12px;
      background: #E0E1E2;
      align-self: end;
      margin: 0 4px 0 0;

      &:first-child {
        margin: 0 4px 0 14px;
      }

      &:last-child {
        margin: 0 14px 0 0;
      }

      & > .label {
        background: #E42E24 !important;
      }
    }

    & > .item.active {
      background: #4183C4;
      color: white;
    }
  }
`;

const MobileTabs = ({ children, onTabChange, activeIndex, counters }) => {
    const { t } = useTranslation();

    const panes = [
        {
            menuItem: t('allShippings'),
            render: () => <TabPane as="div">{children}</TabPane>,
        },
        {
            menuItem: (
                <MenuItem key="auctions">
                    <Label size="mini" circular floating>
                        {counters[1]}
                    </Label>

                    {t('auctions')}
                </MenuItem>
            ),
            render: () => <TabPane as="div">{children}</TabPane>,
        },
        {
            menuItem: (
                <MenuItem key="nominated">
                    <Label size="mini" circular floating>
                        {counters[2]}
                    </Label>

                    {t('ShippingGridMode.Assigned')}
                </MenuItem>
            ),
            render: () => <TabPane as="div">{children}</TabPane>,
        },
        {
            menuItem: t('ShippingGridMode.InProgress'),
            render: () => <TabPane as="div">{children}</TabPane>,
        },
        {
            menuItem: t('ShippingGridMode.Finished'),
            render: () => <TabPane as="div">{children}</TabPane>,
        },
    ];

    const handleChange = (e, data) => {
        onTabChange(data);
    };

    return (
        <StyledTab
            style={{ height: '48px' }}
            activeIndex={activeIndex}
            renderActiveOnly
            menu={{ secondary: true }}
            panes={panes}
            onTabChange={handleChange}
        />
    );
};

export default MobileTabs;
