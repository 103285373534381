export const LOGIN_LINK = '/login';
export const ROLES_LINK = '/roles';
export const ROLE_LINK = '/roles/:id';
export const NEW_ROLE_LINK = '/roles/new';
export const USERS_LINK = '/users';
export const USER_LINK = '/users/:id';
export const NEW_USER_LINK = '/users/new';
export const PROFILE_LINK = '/profile';
export const GRID_LIST_LINK = '/grid/:name';
export const GRID_CARD_LINK = '/grid/:name/:id';
export const GRID_NEW_LINK = '/grid/:name/new';
export const REPORT_LINK = '/report/:type';
export const DICTIONARY_LIST_LINK = '/dictionary/:name';
export const DICTIONARY_CARD_LINK = '/dictionary/:name/:id';
export const DICTIONARY_NEW_LINK = '/dictionary/:name/new';
export const FIELDS_SETTING_LINK = '/fields_setting';
export const PARAMS_SETTING_LINK = '/setting_params/';
export const PARAMS_TRANSPORT_COMPANY_SETTING_LINK = '/setting_params/:id';
export const ADMIN_TEMPLATES_LINK = '/admin_templates';
export const TC_FORM_NO_LOGGED_LINK = '/registrationTransportCompany/:id';
export const REG_LINK = '/registrationTransportCompany';
export const BANNERS_LINK = '/banners';
export const PERSONAL_CABINET_LINK = '/personalCabinet/:pageName';
export const PERSONAL_CABINET_NEW_LINK = '/personalCabinet/:pageName/new';
export const PERSONAL_CABINET_CARD_LINK = '/personalCabinet/:pageName/:id';
export const SHIPPING_ANOMALY_PHOTOS_LINK = '/anomaly/shipping/photos/:id';
export const STOPPER_ANOMALY_PHOTOS_LINK = '/anomaly/shipping/stopper/photos/:id';
export const SHURL_LINK = '/shurl/:id';
export const API_LINK = '/api';
export const LANDING_LINK = '/lp';

export const customPage = [ROLES_LINK, USERS_LINK, FIELDS_SETTING_LINK, PARAMS_SETTING_LINK, PARAMS_TRANSPORT_COMPANY_SETTING_LINK, ADMIN_TEMPLATES_LINK, TC_FORM_NO_LOGGED_LINK, BANNERS_LINK, REG_LINK, PERSONAL_CABINET_LINK];