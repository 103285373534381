import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    List,
} from 'semantic-ui-react';
import { FloatingBubble } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import qs from 'qs';

import { getListRequest, listSelector, totalCountSelector, progressSelector } from '../../../ducks/dictionaryView';
import { scrollSelector, saveScroll } from '../../../ducks/scroll';
import { DICTIONARY_NEW_LINK, DICTIONARY_CARD_LINK } from '../../../router/links';
import { PAGE_SIZE } from '../../../constants/settings';
import StateValue from '../../../components/ColumnsValue/StateValue';
import { MobileFilters, MobileCard } from '../../components';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';

const columns = {
    trailers: [
        {
            kind: 'select',
            name: 'securityServiceCheckState',
            source: 'securityServiceCheckState',
            title: 'status',
            icon: 'calendar alternate outline',
            params: {
                name: 'securityServiceCheckState',
                entities: 'trailers',
                isForm: true,
                isFilter: true,
                params: {
                    filter: { search: '' },
                },
            },
        },
        {
            kind: 'select',
            name: 'vehicleTypeId',
            source: 'list',
            title: 'vehicleTypeId',
            icon: 'map marker alternate',
            params: {
                name: 'vehicleTypeId',
                entities: 'shippings',
                isFilter: true,
            },
        },
    ],
    vehicles: [
        {
            kind: 'select',
            name: 'securityServiceCheckState',
            source: 'securityServiceCheckState',
            title: 'status',
            icon: 'calendar alternate outline',
            params: {
                name: 'securityServiceCheckState',
                entities: 'trailers',
                isForm: true,
                isFilter: true,
                params: {
                    filter: { search: '' },
                },
            },
        },
        {
            kind: 'select',
            name: 'vehicleTypeId',
            source: 'list',
            title: 'vehicleTypeId',
            icon: 'map marker alternate',
            params: {
                name: 'vehicleTypeId',
                entities: 'shippings',
                isFilter: true,
            },
        },
    ],
    transportCompanyDrivers: [
        {
            kind: 'text',
            name: 'firstName',
            title: 'firstName',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'lastName',
            title: 'lastName',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'patronymic',
            title: 'patronymic',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'telephoneNumber',
            title: 'telephoneNumber',
            icon: 'map marker alternate',
            params: {},
        },
    ],
    deliveryAddresses: [
        {
            kind: 'select',
            name: 'deliveryPointType',
            title: 'deliveryPointType',
            icon: 'map marker alternate',
            params: { entities: 'deliveryAddresses', isFilter: true, params: { filter: { search: '' } } },
        },
        {
            kind: 'text',
            name: 'address',
            title: 'address',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'contactPerson',
            title: 'contactPerson',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'telephoneNumber',
            title: 'telephoneNumber',
            icon: 'map marker alternate',
            params: {},
        },
    ],
};

const SecurityServiceWrapper = ({ value, index }) => (
    <StateValue
        value={value}
        source="objectSecurityCheckState"
        indexRow={index}
    />
);

const TelephoneNumberWrapper = ({ value }) => (
    <a href={`tel:${value}`}>{value}</a>
);

const defaultColumns = {
    vehicles: [
        {
            name: 'vehicleTypeId', title: 'vehicleTypeId',
        },
        { name: 'vehicleNumber', title: 'number' },
        { name: 'securityServiceCheckState', title: 'Статус', wrapper: SecurityServiceWrapper },
    ],
    trailers: [
        {
            name: 'vehicleTypeId', title: 'vehicleTypeId',
        },
        { name: 'trailerNumber', title: 'number' },
        { name: 'securityServiceCheckState', title: 'Статус', wrapper: SecurityServiceWrapper },
    ],
    transportCompanyDrivers: [
        {
            name: 'lastName', title: 'lastName',
        },
        {
            name: 'firstName', title: 'firstName',
        },
        { name: 'patronymic', title: 'patronymic' },
        { name: 'telephoneNumber', title: 'telephoneNumber', wrapper: TelephoneNumberWrapper },
    ],
    deliveryAddresses: [],
};

const urlParams = new URLSearchParams();

const DictionaryPage = ({ getList, list, totalCount, saveScrollReq }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { name } = useParams();

    const isInitial = useRef(true);

    const initialScroll = useSelector((state) => scrollSelector(state, name));
    const loading = useSelector(progressSelector);

    const urlGetParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

    const defaultFilters = {
        ...urlGetParams,
        search: urlGetParams.search || '',
        ...(urlGetParams.vehicleTypeId ? { vehicleTypeId: urlGetParams.vehicleTypeId?.split('|') } : {}),
        ...(urlGetParams.securityServiceCheckState ? { securityServiceCheckState: urlGetParams.securityServiceCheckState?.split('|') } : {}),
        ...(urlGetParams.deliveryPointType ? { deliveryPointType: urlGetParams.deliveryPointType?.split('|') } : {}),
    };

    const [filters, setFilters] = useState(defaultFilters);
    const [isUpdate, setIsUpdate] = useState(false);

    const isCard = name === 'deliveryAddresses';
    const hasMore = list.length < totalCount;

    const loadMore = ({ isConcat = false, isReload = false }) => {
        getList({
            name,
            filter: {
                filter: {
                    ...filters,
                    vehicleTypeId: filters.vehicleTypeId?.join('|'),
                    securityServiceCheckState: filters.securityServiceCheckState?.join('|'),
                    deliveryPointType: filters.deliveryPointType?.join('|'),
                },
                take: isInitial.current && list.length ? list.length : PAGE_SIZE,
                skip: isReload ? 0 : list?.length,
            },
            isConcat,
            isReload,
        });

        isInitial.current = false;
    };

    const [bottomRef] = useInfiniteScroll({
        loadMore: () => loadMore({ isConcat: true }),
        hasMore: list.length < totalCount,
        dataLength: list.length,
        initialScroll,
        saveScroll: saveScrollReq,
        name,
    });
    
    const updateUrlParams = (newData) => {
        Object.entries(newData).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
                urlParams.set(key, Array.isArray(value) ? value.join('|') : value);
            } else {
                urlParams.delete(key);
            }
        });

        history.push({ search: urlParams.toString() });
    };

    const handleChangeFilters = (newFilters) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...newFilters,
        }));

        updateUrlParams({
            ...filters,
            ...newFilters,
        });
    };

    const handleResetFilters = (newFilters) => {
        setFilters(newFilters);

        updateUrlParams(newFilters);
    };

    const onClick = () => {
        history.push(DICTIONARY_NEW_LINK.replace(':name', name));
    };

    useEffect(() => {
        if (isUpdate) {
            setIsUpdate(false);
        }

        loadMore({ isConcat: false, isReload: true });
    }, [isUpdate, filters]);

    const renderCardContent = () => (
        <List style={{ margin: '2px 4px' }}>
            {list.map((item, index) => (
                <List.Item key={item?.id}>
                    <MobileCard isHeader indexRow={index} data={item} />
                </List.Item>
            ))}
        </List>
    );

    const renderTableContent = () => (
        <Table fixed collapsing singleLine unstackable size="small" style={{ width: '100%' }}>
            <TableHeader>
                <TableRow>
                    {defaultColumns[name].map((model) => (
                        <TableHeaderCell key={model.name}>{t(model.title)}</TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={item?.id} onClick={() => {
                        history.push({
                            pathname: DICTIONARY_CARD_LINK.replace(':name', name).replace(':id', item?.id),
                        });
                    }}>
                        {!!defaultColumns[name]?.length && defaultColumns[name].map((model) => (
                            <TableCell key={model.name + item?.id} style={{ textWrap: 'balance' }}>
                                {model.wrapper ? (
                                    <model.wrapper value={item[model.name]} index={index} />
                                ) : item[model.name]?.name || item[model.name]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );

    return (
        <>
            <MobileFilters
                columns={columns[name]}
                name={name}
                onChange={handleChangeFilters}
                onReset={handleResetFilters}
                onUpdate={() => setIsUpdate(true)}
            />


            {isCard ? renderCardContent() : renderTableContent()}

            {(loading || hasMore) && (
                <p ref={bottomRef}>{t('data_loading')}...</p>
            )}

            <FloatingBubble
                style={{
                    '--initial-position-bottom': '24px',
                    '--initial-position-right': '24px',
                    '--edge-distance': '24px',
                    '--background': '#22BA46',
                }}
                onClick={onClick}
            >
                <AddOutline fontSize={32} />
            </FloatingBubble>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getList: (params) => {
        dispatch(getListRequest(params));
    },
    saveScrollReq: (params) => {
        dispatch(saveScroll(params));
    },
});

const mapStateToProps = (state) => ({
    list: listSelector(state),
    totalCount: totalCountSelector(state),
});

const WrappedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DictionaryPage);

export default WrappedPage;
