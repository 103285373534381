import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormField, Form, Input, Message,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {useHistory} from "react-router-dom";
import { LOGIN_LINK, REG_LINK } from '../../../router/links';

import {
    errorSelector,
    getLoginPageRequest,
    loginPageSelector,
    loginRequest,
    progressSelector,
} from '../../../ducks/login';
import { AppLogoIcon } from '../../components/Icons';
import {
    StyledLogo,
    StyledLoginWrapper,
    StyledLogin,
    StyledLoginTitle,
    StyledLoginButton,
    StyledLoginLink,
} from './styles';
import SignUpForm from '../../../containers/login/signUpForm';

const LoginMobile = ({ openSignUpForm = false }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector((state) => loginPageSelector(state)) || {};
    const error = useSelector((state) => errorSelector(state));
    const loginProgress = useSelector((state) => progressSelector(state, 'login_progress'));
    const { form: formElements = {} } = page;
    const { inputs = [], login_btn = {} } = formElements;
    const history = useHistory();

    const [form, setForm] = useState({});

    useEffect(() => {
        dispatch(getLoginPageRequest());
    }, []);

    const handleChange = (e, { name, value }) => {
        const values = {
            ...form,
            [name]: value,
        };
        setForm(values);
    };

    const handleFormSubmit = () => {
        const params = {
            form: {
                ...form,
                language: i18n.language,
            },
            api: login_btn.api,
        };
        dispatch(loginRequest(params));
    };

    return (
        <StyledLoginWrapper>
            <StyledLogin>
                <StyledLogo>
                    <AppLogoIcon />
                </StyledLogo>

                <div style={{ width: '100%' }}>
                    <StyledLoginTitle>{t('forTransportCompanies')}</StyledLoginTitle>

                    {
                        openSignUpForm 
                            ? <SignUpForm />
                            : (
                                <Form error={!!error} onSubmit={handleFormSubmit}>
                                    {inputs.map((input) => (
                                        <FormField key={input.name}>
                                            <Input
                                                error={!!error}
                                                fluid
                                                icon={input.icon}
                                                iconPosition="left"
                                                name={input.name}
                                                value={form[input.name]}
                                                placeholder={t(input.name)}
                                                type={input.type}
                                                onChange={handleChange}
                                            />
                                        </FormField>
                                    ))}

                                    <Message
                                        error
                                        header={t('accessError')}
                                        content={t('UserNotFound')}
                                    />

                                    <StyledLoginButton
                                        primary
                                        fluid
                                        loading={loginProgress}
                                        size="big"
                                        icon="log out"
                                        type="submit"
                                        content={t('sign-in')}
                                    />
                                </Form>
                            )
                    }
                    <div align="center">
                        <a
                            onClick={() => {
                                history.push(openSignUpForm ? LOGIN_LINK : REG_LINK);
                            }}
                        >
                            {t(openSignUpForm ? 'sign-in' : 'sign-up')}
                        </a>
                    </div>
                    <StyledLoginLink href="mailto:ru-hmk-tra-utk@fmlogistic.com">
                        {t('contactSupport')}
                    </StyledLoginLink>
                </div>
            </StyledLogin>
        </StyledLoginWrapper>
    );
};

export default LoginMobile;
