import React, {useEffect, useState} from 'react';
import {Dropdown, Form, Input, Loader} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {
    getInnsRequest,
    innsSelector, isDadataSelector, progressSelector,
} from "../../ducks/lookup";

const Inn = ({
                 value = '',
                 name,
                 isDisabled,
                 onChange,
                 isRequired,
                 error,
                 onClickInnInput,
                 placeholder,
                 source,
                 text
             }) => {
    const {t} = useTranslation();

    const [searchValue, setSearchValue] = useState(value);
    const [dadataSearch, setDadataSearch] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const isLoading = useSelector(state => progressSelector(state));
    const valuesList = useSelector(state => innsSelector(state));
    const isDadata = useSelector(state => isDadataSelector(state));
    const dispatch = useDispatch();

    let errorData = !searchValue || (searchValue && searchValue.length !== 10 && searchValue.length !== 12);

    useEffect(() => {
        !errorData && dispatch(
            getInnsRequest({
                inn: searchValue,
                isDaData: dadataSearch,
                source
            }),
        );
    }, [searchValue, dadataSearch, errorData, source]);

    useEffect(() => {
        !isOpen && setSearchValue(value);
    }, [isOpen, value]);

    const handleSearchChange = (e, {value}) => {
        setDadataSearch(false);
        if (value.length <= 12 && (value.length === 0 || /^\d+$/.test(value))) setSearchValue(value);
    };

    const handleFocus = () => {
        setIsOpen(true);
    }

    const handleBlur = () => {
        setIsOpen(false);
    }

    const handleChange = (e, {value}) => {
        onChange(e, {value: value ? value : null, name});
        handleBlur();
    };

    const onClickAddInn = (e) => {
        onClickInnInput && onClickInnInput(e, {value: searchValue, name});
        handleBlur();
    }

    const getItem = (item) => {
        switch (source) {
            case 'getInfoByInn':
                return <div>
                    {item.name}
                </div>;
            default:
                return <>
                    <div className='item item_header'>
                        {item.carrierInn}, {item.carrier}
                    </div>
                    <div className='item item_text'>
                        {item.carrierLegalAddress}{item.carrierTelephoneNumber ? `, ${t('carrierPhoneNumber', {phone: item.carrierTelephoneNumber})}` : ''}
                    </div>
                </>
        }
    }

    const items = <>
        {
            valuesList && valuesList.length ? (
                valuesList.map((item, i) => (
                    <Dropdown.Item
                        key={i}
                        onClick={e => handleChange(e, {value: {inn: searchValue, ...item}})}
                    >
                        {getItem(item)}
                    </Dropdown.Item>
                ))
            ) : (<div className="message">{t("noSearchResults")}</div>)
        }
        {
            (onClickInnInput && !source) && (
                (dadataSearch || isDadata) ?
                    <Dropdown.Item className='inn-button' onClick={onClickAddInn}>
                        {(valuesList && valuesList.length) ? t("addAnotherСarrier") : t("addСarrier")}
                    </Dropdown.Item> :
                    <Dropdown.Item className='item inn-button' onClick={() => setDadataSearch(true)}>
                        {t("continueSearchInYegryul")}
                    </Dropdown.Item>
            )
        }
        {
            (source && (!valuesList || !valuesList.length)) && <Dropdown.Item
                className='inn-button'
                onClick={onClickAddInn}
            >
                {t("enterManually")}
            </Dropdown.Item>
        }
    </>

    const loadingMessage = <div>
        <div className="loading-message">
            <Loader className="loading-message_loader" active inline/>
            {t("searchByInn")}
        </div>
    </div>

    const trigger = <Input
        disabled={isDisabled}
        placeholder={t(placeholder)}
        value={searchValue}
        onFocus={handleFocus}
        onChange={handleSearchChange}
        error={error}
    />

    return (
        <Form.Field>
            <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
            </label>
            <div className={`form-select ${isDisabled ? 'form-select_disabled' : ''}`}>
                <Dropdown
                    icon={null}
                    fluid
                    trigger={trigger}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    open={isOpen}
                    openOnFocus={false}
                >
                    <Dropdown.Menu className={`inn-menu ${!errorData ? 'inn-menu_items' : ''}`}>
                        {!errorData ?
                            (!isLoading ? items : loadingMessage) :
                            <div className="message item_header">{t("enterCharactersToSearch")}</div>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Form.Field>
    );
};
export default Inn;
