import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
    Modal, Card, Button, Icon,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

import { formatDate, parseDate } from '../../../utils/dateTimeFormater';

export const StyledModal = styled(Modal)`
    && {
        height: 95%;
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }
`;

export const StyledModalHeader = styled(Modal.Header)`
    text-align: center;
    font-size: 16px;
    padding: 25px 0;
`;

export const StyledModalContent = styled(Modal.Content)`
    flex-grow: 1;
`;

const StyledDatePicker = styled.div`
    width: 100%;
    border: 1px solid #135CA9;

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__week {
        white-space: nowrap;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .react-datepicker__day--in-range,
        .react-datepicker__day--selected,
        .react-datepicker__day--keyboard-selected {
            background-color: #1C78D3 !important;
        }

        .react-datepicker__day--keyboard-selected {
            color: #fff !important;
        }
    }

    .react-datepicker__header {
        border: none;
        background: none;

        .react-datepicker__current-month {
            margin: 4px auto;
            color: #0000008A;
            font-size: 14px;
        }

        .react-datepicker__day-names {
            color: gray;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .react-datepicker__day-name {
                color: inherit;
                font-size: 12px;
            }
        }
    }
`;

const CalendarModal = ({
    title, iconName, visible, onClose, onConfirm, defaultValue,
}) => {
    const { t } = useTranslation();
    const [dates, setDates] = useState(defaultValue ? defaultValue.map(parseDate) : [Date.now()]);

    const onChange = (newDates) => {
        setDates(newDates.filter(Boolean));
    };

    const reset = () => {
        setDates([]);
    };

    const confirm = (e) => {
        e.stopPropagation();

        onConfirm(dates.filter(Boolean).map((date) => formatDate(date)));
    };

    return (
        <StyledModal open={visible} onClose={onClose} size="fullscreen" closeIcon>
            <StyledModalHeader content={t('filters')} />

            <StyledModalContent>
                <Card fluid>
                    <Card.Content>
                        <Card.Header>
                            <Icon name={iconName} style={{ marginRight: '8px' }} />

                            {title}
                        </Card.Header>
                    </Card.Content>

                    <Card.Content>
                        <DatePicker
                            selected={dates[0]}
                            onChange={onChange}
                            startDate={dates[0]}
                            endDate={dates[1]}
                            locale={localStorage.getItem('i18nextLng')}
                            dateFormat="dd.MM.yyyy"
                            selectsRange
                            inline
                            showDisabledMonthNavigation
                            calendarContainer={StyledDatePicker}
                        />

                        <p>
                            {`${t('selectedDates')} ${dates.filter(Boolean).map((date) => formatDate(date)).join(' - ')}`}
                        </p>
                    </Card.Content>
                </Card>
            </StyledModalContent>

            <Modal.Actions>
                <Button style={{ marginLeft: '0' }} fluid onClick={reset}>
                    {t('resetAll')}
                </Button>

                <Button color="blue" style={{ marginLeft: '0' }} fluid onClick={confirm}>
                    {t('applyBtn')}
                </Button>
            </Modal.Actions>
        </StyledModal>
    );
};

export default CalendarModal;
