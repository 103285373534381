import React from 'react';
import {Button, Form, Grid, Icon, Popup, Segment} from "semantic-ui-react";
import {EMAIL_TYPE, PHONE_NUMBER_TYPE, TEXT_TYPE} from "../../../../constants/columnTypes";
import FormField from "../formField";
import {useTranslation} from "react-i18next";


const EmployeeContact = ({isFirst, form, onChange: onChangeValue, error, isDisabled, position}) => {
    const {t} = useTranslation();

    const deleteRow = () => {
        onChangeValue(form, true)
    }

    const add = () => {
        onChangeValue({
            position
        })
    }

    const onChange = (e, {name, value}) => {
        onChangeValue({
            ...form,
            [name]: value
        })
    }

    return <Form className='company-contacts__form'>
        {(!isFirst && !isDisabled) && <div className='company-contacts__del'>
            <Popup
                content={t('delete')}
                position="top center"
                trigger={
                    <Icon
                        name="trash alternate"
                        onClick={deleteRow}
                    />
                }
            />
        </div>}
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column>
                    <FormField
                        type={TEXT_TYPE}
                        isRequired
                        name="lastName"
                        form={form}
                        error={error}
                        onChange={onChange}
                        isDisabled={isDisabled}
                    />
                </Grid.Column>
                <Grid.Column>
                    <FormField
                        type={TEXT_TYPE}
                        isRequired
                        name="name"
                        displayNameKey="contactName"
                        form={form}
                        error={error}
                        onChange={onChange}
                        isDisabled={isDisabled}
                    />
                </Grid.Column>
                <Grid.Column>
                    <FormField
                        type={TEXT_TYPE}
                        isRequired
                        name="patronymic"
                        form={form}
                        error={error}
                        onChange={onChange}
                        isDisabled={isDisabled}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column>
                    <FormField
                        type={PHONE_NUMBER_TYPE}
                        isRequired
                        name="phoneNumber"
                        form={form}
                        error={error}
                        onChange={onChange}
                        isDisabled={isDisabled}
                    />
                </Grid.Column>
                <Grid.Column>
                    <FormField
                        type={EMAIL_TYPE}
                        isRequired
                        name="email"
                        form={form}
                        error={error}
                        onChange={onChange}
                        isDisabled={isDisabled}
                    />
                </Grid.Column>
                {isFirst && <Grid.Column className='company-contacts__add'>
                    <Button
                        primary
                        onClick={add}
                        disabled={isDisabled}
                    >
                        {t('addEmployee')}
                    </Button>
                </Grid.Column>}
            </Grid.Row>
        </Grid>
    </Form>
}

const EmployeesContacts = ({value, label, contacts, onChange, disabled}) => {
    const values = (contacts || []).length
        ? contacts
        : [{
            position: value,
            id: Date.now()
        }];

    const onChangeContact = (contactData, isDelete) => {
        let val = [...(values || [])];

        if (isDelete) {
            onChange([contactData], true)
            return;
        } else if (contactData.id) {
            const index = val.findIndex(v => v.id === contactData.id);
            val[index] = contactData;
        } else {
            val.push({
                ...contactData,
                id: Date.now()
            })
        }
        onChange(val)
    }

    return (
        <Segment className='margin-bottom-24'>
            <label>{label}</label>
            {
                values.map((contact, index) => <EmployeeContact
                    key={`${contact.id}_${index}`}
                    form={contact}
                    isFirst={index === 0}
                    onChange={onChangeContact}
                    position={value}
                    isDisabled={disabled}
                />)
            }
        </Segment>
    );
};

export default EmployeesContacts;