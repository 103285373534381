import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Dropdown,
    Modal,
    Button,
    Icon,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { StyledModal, StyledModalHeader, StyledModalContent } from './styles';

const DropdownModal = ({
    title,
    visible,
    iconName = '',
    selectedItemsIds,
    onClose,
    onConfirm,
    options,
    onOpen,
}) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [value, setValue] = useState(selectedItemsIds || []);

    const handleChange = (e, { value }) => {
        setSearchQuery('');
        setValue(value);
    };

    const handleSearchChange = (e, { searchQuery }) => setSearchQuery(searchQuery);

    const reset = () => {
        setSearchQuery('');
        setValue([]);
    };

    const confirm = (e) => {
        e.stopPropagation();

        onConfirm(value);
    };

    useEffect(() => {
        onOpen();
    }, []);

    return (
        <StyledModal open={visible} onClose={onClose} size="fullscreen" closeIcon>
            <StyledModalHeader content={t('filters')} />

            <StyledModalContent>
                <Card fluid>
                    <CardContent>
                        <CardHeader>
                            <Icon name={iconName} style={{ marginRight: '8px' }} />

                            {title}
                        </CardHeader>
                    </CardContent>

                    <CardContent>
                        <Dropdown
                            fluid
                            search
                            multiple
                            selection
                            value={value}
                            options={options}
                            placeholder={title}
                            searchQuery={searchQuery}
                            onChange={handleChange}
                            onSearchChange={handleSearchChange}
                        />
                    </CardContent>
                </Card>
            </StyledModalContent>

            <Modal.Actions>
                <Button style={{ marginLeft: '0' }} fluid onClick={reset}>
                    {t('resetAll')}
                </Button>

                <Button color="blue" style={{ marginLeft: '0' }} fluid onClick={confirm}>
                    {t('applyBtn')}
                </Button>
            </Modal.Actions>
        </StyledModal>
    );
};

export default DropdownModal;
