import { all, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

//*  TYPES  *//
const SAVE_SCROLL = 'SAVE_SCROLL';

//*  INITIAL STATE  *//

const initial = {};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case SAVE_SCROLL:
            return {
                ...state,
                [payload.name]: payload.value,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const saveScroll = (payload) => {
    return {
        type: SAVE_SCROLL,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.scroll;
const gridName = (state, name) => name;

export const scrollSelector = createSelector([stateSelector, gridName], (state, name) => state[name]);

//*  SAGA  *//

function* saveScrollSaga({ payload }) {
    yield put({
        type: SAVE_SCROLL,
        payload,
    });
}

export function* saga() {
    yield all([takeEvery(SAVE_SCROLL, saveScrollSaga)]);
}
