import React from 'react';
import {Button} from "semantic-ui-react";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {useTranslation} from "react-i18next";
import {DICTIONARY_CARD_LINK} from "../../../../router/links";

const RequiredDocsModal = ({open, text, onClose, objId, dictionary}) => {
    const {t} = useTranslation();

    const goToCard = () => {
        window.open(DICTIONARY_CARD_LINK.replace(':name', dictionary).replace(':id', objId), '_blank')
    }

    return (
        <RequiredFieldsModal
            title={t('requiredDocumentsModalTitle')}
            open={open}
            text={`${t('requiredDocumentsModalText')} ${text}`}
            withoutSave
            btns={[
                <Button onClick={onClose}>
                    {t('CancelButton')}
                </Button>,
                <Button
                    primary
                    onClick={() => {
                        onClose();
                        goToCard()
                    }}
                >
                    {t('goToCard')}
                </Button>
            ]}
        />
    );
};

export default RequiredDocsModal;