import React, { useEffect, useRef } from 'react';

export default function useInfiniteScroll({ name, loadMore, hasMore, dataLength, initialScroll = 0, saveScroll }) {
    const observerRef = useRef(null);
    const bottomRef = useRef(null);
  
    const handleObserver = (entries) => {
        const target = entries[0];

        if (target.isIntersecting && hasMore) {
            loadMore()
        }
    };

    useEffect(() => {
        observerRef.current = new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        });

        if (bottomRef.current) {
            observerRef.current.observe(bottomRef.current);
        }

        return () => {
            if (observerRef.current && bottomRef.current) {
                observerRef.current.unobserve(bottomRef.current);
            }
        };
    }, [dataLength]);

    useEffect(() => {
        window.scrollTo(0, parseInt(initialScroll, 10));

        const handleScroll = () => {
            saveScroll({ name, value: window.scrollY });
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return [bottomRef, hasMore];
}