export const backlightsColorsStyle = (row, backgroundColors, textColors) => {
    if (row.backlights && row.backlights.length) {
        const styles = {};
        let backgroundColor = null;
        let textColor = null;
        Object.keys(backgroundColors || {}).forEach(c => {
            if (row.backlights.includes(c)) {
                backgroundColor = backgroundColors[c]
            }
        })
        Object.keys(textColors || {}).forEach(c => {
            if (row.backlights.includes(c)) {
                textColor = textColors[c]
            }
        })
        if (backgroundColor) {
            styles.backgroundColor = backgroundColor
        }
        if (textColor) {
            styles.color = textColor
        }
        return styles;
    }
    return null;
}