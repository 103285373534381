import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button, Form, Modal} from "semantic-ui-react";
import FormField from "../customGrid/components/formField";
import {TEXT_TYPE} from "../../constants/columnTypes";
import {daDataListSelector, getDaDataRequest, progressSelector} from "../../ducks/lookup";
import qs from "qs";
import {debounce} from "throttle-debounce";
import {editProgressSelector, regTCByInnRequest} from "../../ducks/gridCard";
import {REG_LINK, TC_FORM_NO_LOGGED_LINK} from "../../router/links";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const ContinueRegModal = ({open, onClose: close}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {t} = useTranslation();
    const [form, setForm] = useState({});
    const inn = 'inn';
    const companyName = 'companyName';
    const list = useSelector(state => daDataListSelector(state, inn, 'name'));
    const searchCompanyByInnProgress = useSelector(state => progressSelector(state));
    const searchFormByInnProgress = useSelector(state => editProgressSelector(state));
    const progress = searchFormByInnProgress || searchCompanyByInnProgress;

    const getList = (value) => {
        dispatch(getDaDataRequest({
            source: 'getInfoByInn',
            name: inn,
            params: qs.stringify({
                [inn]: value,
            })
        }))
    };

    const getListDebounce = useCallback(debounce(300, getList), []);

    const onChange = (_, {name, value}) => {
        setForm(form => ({
            ...form,
            [name]: value
        }))
    }

    const innCheck = (value) => {
        return ((value || '').length === 10 || (value || '').length === 12);
    }

    const changeInn = (_, {value}) => {
        onChange(null, {name: inn, value})
        if (innCheck(value)) {
            getListDebounce(value)
        } else {
            onChange(null, {name: companyName, value: ''})
        }
    }

    useEffect(() => {
        const compName = (list || []).length ? list[0].name : '';

        onChange(null, {
            name: companyName,
            value: compName
        })
    }, [list]);

    useEffect(() => {
        setForm({});
    }, [open]);

    const onClose = () => {
        close && close();
    }

    const callback = (result) => {
        if (result) {
            history.push(TC_FORM_NO_LOGGED_LINK.replace(':id', result))
        } else {
            toast.error(<>
                <div className='margin-bottom-10'>{t('continueRegErrorNotFound')}</div>
                <Button
                    size='mini'
                    primary
                    onClick={() => {
                        history.push(REG_LINK)
                    }}
                >
                    {t('sign-up')}
                </Button>
            </>, {
                pauseOnHover: true
            })
        }
    }

    const onClickOk = () => {
        dispatch(regTCByInnRequest({
            ...form,
            callback
        }))
    }

    return (
        <Modal
            onSubmit={onClickOk}
            open={open}
            size={'small'}
            className={'required-fields-modal'}
        >
            <Modal.Header>{t('continueRegistration')}</Modal.Header>
            <Modal.Content>
                <Form style={{textAlign: 'center'}}>
                    <Form.Group>
                        <FormField
                            text={t('enterYourInn')}
                            maxLength={12}
                            name={inn}
                            type={TEXT_TYPE}
                            form={form}
                            isRequired
                            onChange={changeInn}
                        />
                    </Form.Group>
                    <Form.Group>
                        <FormField
                            text={t(companyName)}
                            name={companyName}
                            type={TEXT_TYPE}
                            form={form}
                            isRequired
                            isDisabled
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    loading={progress}
                    disabled={!form[inn] || !form[companyName] || progress}
                    primary
                    onClick={onClickOk}
                >
                    {t('ok')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ContinueRegModal;