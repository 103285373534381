import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    checkDriverSelector,
    checkObjectSelector,
    setCheckDriver,
    setCheckObject,
    setRequiredFieldsFromModal
} from "../../../../ducks/gridCard";
import DriverInnModal from "./driverInnModal";
import RequiredDocsModal from "./requiredDocsModal";
import DriverLicenseModal from "./driverLicenseModal";
import ModelModal from "./modelModal";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {ENUM_TYPE} from "../../../../constants/columnTypes";
import {useTranslation} from "react-i18next";

const ModalsBlock = ({
                         form,
                         error,
                         setDriver,
                         handleChangeOwnershipType,
                     }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const trailerId = 'trailerId';
    const vehicleId = 'vehicleId';

    const [openDriverInnModal, setOpenDriverInnModal] = useState(false);
    const [openDriverLicenseModal, setOpenDriverLicenseModal] = useState(false);
    const [openVehicleModelModal, setOpenVehicleModelModal] = useState(false);
    const [openTrailerModelModal, setOpenTrailerModelModal] = useState(false);
    const [openFieldsModal, setOpenFieldsModal] = useState(false);

    const [requiredDriverModal, setRequiredDriverModal] = useState(false);
    const [requiredVehicleModal, setRequiredVehicleModal] = useState(false);
    const [requiredTrailerModal, setRequiredTrailerModal] = useState(false);

    const checkVehicleResult = useSelector(state => checkObjectSelector(state, 'vehicle'));
    const checkTrailerResult = useSelector(state => checkObjectSelector(state, 'trailer'));
    const checkDriverData = useSelector(state => checkDriverSelector(state));

    const checkInnDriver = checkDriverData.checkInn;
    const checkLicenseDriver = checkDriverData.checkLicense;
    const requiredDriver = checkDriverData.required;

    const checkModelVehicleResult = checkVehicleResult.haveModel;
    const checkFields = checkVehicleResult.haveEngineAndFuelTypes;
    const checkModelTrailerResult = checkTrailerResult.haveModel;
    const requiredVehicle = checkVehicleResult.required;
    const requiredTrailer = checkTrailerResult.required;

    useEffect(() => {
        setOpenDriverInnModal(!checkInnDriver);
    }, [checkInnDriver]);

    useEffect(() => {
        setOpenDriverLicenseModal(!checkLicenseDriver);
    }, [checkLicenseDriver]);

    useEffect(() => {
        setRequiredDriverModal(requiredDriver || false);
    }, [requiredDriver]);

    useEffect(() => {
        setRequiredVehicleModal(requiredVehicle || false);
    }, [requiredVehicle]);

    useEffect(() => {
        setRequiredTrailerModal(requiredTrailer || false);
    }, [requiredTrailer]);

    useEffect(() => {
        setOpenFieldsModal(!checkFields);
    }, [checkFields]);

    useEffect(() => {
        setOpenVehicleModelModal(!checkModelVehicleResult);
    }, [checkModelVehicleResult]);

    useEffect(() => {
        setOpenTrailerModelModal(!checkModelTrailerResult);
    }, [checkModelTrailerResult]);

    const saveRequiredFields = (data) => {
        dispatch(setRequiredFieldsFromModal(data));
    };

    return (
        <>
            <DriverInnModal
                innRequired={form['isDriverInnRequired']}
                open={openDriverInnModal}
                error={error}
                onClose={() => {
                    setOpenDriverInnModal(false);
                    dispatch(setCheckDriver({checkInn: true}));
                }}
                driverId={form['editableDriverId'] && form['editableDriverId'].value}
            />
            <RequiredDocsModal
                onClose={() => {
                    setRequiredDriverModal(false);
                    dispatch(setCheckDriver({required: false}));
                    setDriver(null, {
                        name: 'editableDriverId',
                        value: null
                    });
                }}
                text={requiredDriverModal}
                open={!!requiredDriverModal}
                dictionary={'transportCompanyDrivers'}
                objId={form['editableDriverId'] && form['editableDriverId'].value}
            />
            <RequiredDocsModal
                onClose={() => {
                    setRequiredVehicleModal(false);
                    dispatch(setCheckObject({name: 'vehicles', required: false}));
                    handleChangeOwnershipType(null, {
                        name: vehicleId,
                        value: null
                    });
                }}
                text={requiredVehicleModal}
                open={!!requiredVehicleModal}
                dictionary={'vehicles'}
                objId={form[vehicleId] && form[vehicleId].value}
            />
            <RequiredDocsModal
                onClose={() => {
                    setRequiredTrailerModal(false);
                    dispatch(setCheckObject({name: 'trailers', required: false}));
                    handleChangeOwnershipType(null, {
                        name: trailerId,
                        value: null
                    });
                }}
                text={requiredTrailerModal}
                open={!!requiredTrailerModal}
                dictionary={'trailers'}
                objId={form[trailerId] && form[trailerId].value}
            />
            <DriverLicenseModal
                open={openDriverLicenseModal}
                error={error}
                onClose={() => {
                    setOpenDriverLicenseModal(false);
                    dispatch(setCheckDriver({checkLicense: true}));
                }}
                driverId={form['editableDriverId'] && form['editableDriverId'].value}
            />
            <ModelModal
                open={openVehicleModelModal}
                error={error}
                onClose={() => {
                    dispatch(setCheckObject({name: 'vehicles', haveModel: true}));
                    setOpenVehicleModelModal(false);
                }}
                id={form[vehicleId] && form[vehicleId].value}
                name='vehicles'
            />
            <ModelModal
                open={openTrailerModelModal}
                error={error}
                onClose={() => {
                    dispatch(setCheckObject({name: 'trailers', haveModel: true}));
                    setOpenTrailerModelModal(false);
                }}
                id={form[trailerId] && form[trailerId].value}
                name='trailers'
            />
            <RequiredFieldsModal
                open={openFieldsModal}
                id={form[vehicleId] && form[vehicleId].value}
                onClose={() => {
                    setOpenFieldsModal(false);
                    dispatch(setCheckObject({name: 'vehicles', haveEngineAndFuelTypes: true}));
                }}
                error={error}
                text={t('enteringEngineTypeAndFuelTypeText')}
                title={t('enteringEngineTypeAndFuelType')}
                fields={[
                    {
                        isRequired: true,
                        name: "engineType",
                        source: "engineType",
                        type: ENUM_TYPE
                    },
                    {
                        isRequired: true,
                        name: "fuelType",
                        source: "fuelType",
                        type: ENUM_TYPE
                    }
                ]}
                saveRequest={saveRequiredFields}
            />
        </>
    );
};

export default ModalsBlock;