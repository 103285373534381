import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, Form} from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import {checkMask} from '../../utils/string';
import {useDeepEffect} from "../../utils/useDeepEffect";

const createMask = (mask) => {
    return (mask || "").split('').map(char => {
        if (char === 'a') return /[A-Za-яЁё]/; // Русские и английские буквы
        if (char === '9') return /\d/;
        return char;
    });
};

const TextWithMask = ({
                          value,
                          name,
                          onChange,
                          onBlur,
                          isDisabled,
                          noLabel,
                          className,
                          text,
                          error,
                          type,
                          datalist: valuesList = [],
                          placeholder,
                          isRequired,
                          autoComplete,
                          autoFocus,
                          warningText,
                          mask: masks
                      }) => {
    const {t} = useTranslation();

    const [mask, setMask] = useState();
    const masksIsArray = Array.isArray(masks);
    const onlyOneMask = !masksIsArray || masks.length < 2;

    useDeepEffect(() => {
        const mask = (!onlyOneMask && value) && masks.find(m => checkMask(m, value)); //автовыбор маски при наличии значения
        setMask(mask || (masksIsArray ? masks[0] : masks));
    }, [masks]);

    const maskOptions = !onlyOneMask && masks.map(mask => ({
       text: mask.toUpperCase(),
       value: mask,
       key: mask
    }))

    useEffect(() => {
        if (mask && value && !checkMask(mask, value)) {
            onChange(null, {
                name,
                value: ''
            })
        }
    }, [mask]);

    const handleOnChange = (e) => {
        onChange(e, {value: e.target.value, name});
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                    {warningText && <span className='label-warning-text'> ({t(warningText)})</span>}
                </label>
            ) : null}
            {mask && <>
                {
                    onlyOneMask
                        ? <div className="field-hint">
                            <i>{`${t('formatHint')} ${mask.toUpperCase()}`}</i>
                        </div>
                        : <div>
                            <span className="field-hint"><i>{t('formatHint')}</i></span>
                            <Dropdown
                                className='dropdown-mask'
                                inline
                                options={maskOptions}
                                value={mask}
                                onChange={(e, {value}) => setMask(value)}
                            />
                        </div>
                }
            </>}
            <div className={error ? 'ui input error' : ''}>
                <MaskedInput
                    mask={createMask(mask)}
                    guide={false}
                    disabled={isDisabled}
                    onChange={handleOnChange}
                    render={(ref, props) => (<input
                        {...props}
                        value={value}
                        ref={ref}
                        placeholder={placeholder}
                        list={valuesList && name}
                        className={className}
                        type={type}
                        disabled={isDisabled}
                        name={name}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                        required={isRequired}
                    />)}
                />
            </div>
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{__html: error}}/>
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name}/>
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    )
}

export default TextWithMask
