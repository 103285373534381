import React from 'react';
import styled from 'styled-components';
import { Card } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { roleSelector } from '../../../ducks/profile';
import StateValue from '../../../components/ColumnsValue/StateValue';
import {
    AddressIcon,
    CalendarIcon,
    AddressOutlineIcon,
    UserOutlineIcon,
    TelephoneIcon,
    ClockFastIcon,
    TemperatureIcon,
    DocumentIcon,
    ShareIcon,
    MoneyIcon,
    AuctionIcon,
} from '../../components/Icons'

const StyledSpan = styled.span`
    font-weight: 500;
    color: #8A8A8A;
`;

const StyledIcon = styled.div`
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledCard = styled(Card)`
    width: 100% !important;

    & .content {
        padding: 10px !important;
    }

    & > .card-header {
        & > .card-header__title {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;

            & > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
            }

            & > .status-value {
                font-size: 12px;
                flex-basis: content;
            }
        }

        & > .card-header__address {
            display: flex;
            gap: 4px;
            align-items: center;

            & > svg {
                min-width: 18px;
            }

            & > span {
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0px;

                word-break: break-all;
            }
        }
    }

    & > .card-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px;
        gap: 3px;
        font-size: 14px !important;;
        align-items: center;

        & > .card-content__item {
            display: flex;
            gap: 4px;
            font-size: 12px;

            &:nth-child(-n+3) {
                width: auto !important;
            }

            & > .card-content__title {
                display: flex;
                gap: 4px;
            }

            & > .card-content__text {
                word-break: break-all;
            }
        }
    }

    & > .card-footer {}
`;

const renderItems = (items, t) => items.filter(Boolean).map(
    ({ icon, label, value }) => (
        <div className="card-content__item" key={label + value}>
            <div className="card-content__title">
                {icon && <StyledIcon>{icon}</StyledIcon>}

                <StyledSpan>{`${t(label)}:`}</StyledSpan>
            </div>

            <div className="card-content__text">{value}</div>
        </div>
    ),
);

const getData = (values, isDocuments, userIsAdminOrCarrier, t) => {
    if (isDocuments) {
        return {
            content: [
                { icon: <CalendarIcon />, label: 'schedule', value: values.schedule },
                { label: 'Type', value: values.deliveryPointType?.name },
                { icon: <AddressOutlineIcon />, label: 'address', value: values.address },
            ],
            otherContent: [
                { icon: <UserOutlineIcon />, label: 'contactPerson', value: values.contactPerson },
                { icon: <TelephoneIcon />, label: 'telephoneNumber', value: <a href={`tel:${values.telephoneNumber}`}>{values.telephoneNumber}</a> },
            ],
        };
    }

    return {
        content: [
            { icon: <ClockFastIcon />, label: 'vehicleTypeId', value: values.vehicleTypeId?.name },
            values.temperatureMin && {
                icon: <TemperatureIcon />,
                label: 'temperatureLabel',
                value: `
                    ${t('from')} ${values.vehicleTypeTemperatureMin || '-'} ${t('to')} ${values.vehicleTypeTemperatureMax || '-'}
                `
            },
            userIsAdminOrCarrier && values.transportCompanyId && { icon: <DocumentIcon />, label: 'Case_TransportCompanyId', value: values.transportCompanyId?.name },
            { icon: <CalendarIcon />, label: 'firstLoadingDateTime', value: values.firstLoadingDateTime },
            { icon: <DocumentIcon />, label: 'uploading', value: `${values.firstLoadingClientName}${values.firstLoadingCity ? `, ${values.firstLoadingCity}` : ''}` },
            { icon: <CalendarIcon />, label: 'firstUnloadingDateTime', value: values.firstUnloadingDateTime },
            { icon: <DocumentIcon />, label: 'unloading', value: `${values.lastUnloadingClientName}, ${values.lastUnloadingCity}` },
        ],
        otherContent: [
            { icon: <ShareIcon />, label: 'loadingStoppersCountShort', value: values.loadingStoppersCount },
            { icon: <ShareIcon />, label: 'unloadingStoppersCountShort', value: values.unloadingStoppersCount },
            { icon: <MoneyIcon />, label: 'ShippingDto.ServiceCost', value: values.basicDeliveryCostWithoutVAT || '-' },
            values.bestAuctionPriceOffer && { icon: <AuctionIcon />, label: 'bestAuctionPriceOfferShort', value: values.bestAuctionPriceOffer },
        ],
    };
};

const MobileCard = ({
    indexRow,
    data,
    isHeader,
}) => {
    const { t } = useTranslation();
    const userRole = useSelector(state => roleSelector(state));
    const userIsAdminOrCarrier = (
        userRole === 'Administrator' || userRole === 'TransportCoordinator'
    );
    const cardData = getData(data, isHeader, userIsAdminOrCarrier, t);

    return (
        <StyledCard>
            {!isHeader ? (
                <Card.Content className="card-header">
                    <div className="card-header__title">
                        <span>{`№ ${data.shippingNumber}`}</span>

                        <StateValue
                            value={data.status}
                            source="shippingState"
                            indexRow={indexRow}
                        />
                    </div>

                    <div className="card-header__address">
                        <AddressIcon />

                        <span>{`${data.fromWhere} - ${data.whereTo}`}</span>
                    </div>
                </Card.Content>
            ) : null}

            <Card.Content className="card-content">
                {renderItems(cardData.content, t)}
            </Card.Content>

            <Card.Content className="card-content">
                {renderItems(cardData.otherContent, t)}
            </Card.Content>
        </StyledCard>
    );
};

export default MobileCard;
