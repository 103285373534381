import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Confirm, Form, Popup} from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import {EMAILS_TYPE, PASSWORD_TYPE, PHONE_NUMBER_TYPE, TEXT_TYPE} from '../../constants/columnTypes';
import {
    addError, deleteProfile,
    editProfileSettingsRequest,
    errorSelector,
    getNotificationsListRequest,
    getProfileSettingsRequest,
    profileSettingsSelector,
    progressSelector
} from '../../ducks/profile';
import CardLayout from '../../components/CardLayout';

const Profile = props => {
    const {history} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({open: false});
    let [form, setForm] = useState({});

    const profile = useSelector(state => profileSettingsSelector(state));

    useEffect(() => {
        dispatch(getProfileSettingsRequest());
        dispatch(getNotificationsListRequest());
    }, []);

    useEffect(
        () => {
            setForm({...profile});
        },
        [profile],
    );

    const progress = useSelector(state => progressSelector(state));

    const error = useSelector(state => errorSelector(state));

    const handleChange = useCallback((e, {name, value}) => {
        if (notChangeForm) {
            setNotChangeForm(false);
        }
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, [notChangeForm, form]);

    const handleSave = () => {
        if (handleComparePassword()) {
            dispatch(
                editProfileSettingsRequest({
                    form,
                    callbackSuccess: onClose,
                }),
            );
        }
    };

    const handleComparePassword = () => {
        const { returnNewPassword, newPassword } = form;

        if (returnNewPassword !== newPassword) {
            dispatch(
                addError({
                    name: 'returnNewPassword',
                    message: t('passwords_do_not_match'),
                }),
            );
            return false;
        }

        return true;
    };

    const confirmClose = () => {
        setConfirmation({open: false});
    };

    const onClose = () => {
        setForm({});
        history.goBack();
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    const onDelete = () => {
        !progress && dispatch(deleteProfile({}))
    }
    
    const handleDelete = () => {
        setConfirmation({
            open: true,
            content: t('deleteProfileConfirmText'),
            onCancel: confirmClose,
            onConfirm: onDelete,
        });
    }

    const getActionsHeader = useCallback(() => {
        return (
            <div>
                <Popup
                    content={t('deleteProfile')}
                    position="bottom right"
                    trigger={
                        <Button 
                            icon="trash alternate outline" 
                            onClick={handleDelete} 
                            content={t('deleteProfile')}
                        />
                    }
                    />
            </div>
        );
    }, []);

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button color="blue" disabled={notChangeForm} onClick={handleSave}>
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form, notChangeForm],
    );

    const getContent = useCallback(
        () => {
            return [
                {
                    menuItem: 'general',
                    render: () => (
                        <Form className="profile-form">
                            <FormField
                                name="userName"
                                type={TEXT_TYPE}
                                value={form['userName']}
                                isRequired
                                error={error['userName']}
                                onChange={handleChange}
                            />
                            <FormField
                                name="email"
                                type={TEXT_TYPE}
                                value={form['email']}
                                isRequired
                                error={error['email']}
                                onChange={handleChange}
                            />
                            <FormField
                                name="telephoneNumber"
                                type={PHONE_NUMBER_TYPE}
                                value={form['telephoneNumber']}
                                error={error['telephoneNumber']}
                                onChange={handleChange}
                            />
                            <FormField
                                name="additionalEmails"
                                type={EMAILS_TYPE}
                                value={form['additionalEmails']}
                                error={error['additionalEmails']}
                                onChange={handleChange}
                            />
                            <FormField
                                name="role"
                                type={TEXT_TYPE}
                                value={form['roleName']}
                                isReadOnly
                                onChange={handleChange}
                            />
                        </Form>
                    ),
                },
                {
                    menuItem: 'Password Change',
                    render: () => (
                        <Form className="profile-form">
                            <FormField
                                name="oldPassword"
                                type={PASSWORD_TYPE}
                                value={form['oldPassword']}
                                error={error['oldPassword']}
                                onChange={handleChange}
                            />
                            <FormField
                                name="newPassword"
                                type={PASSWORD_TYPE}
                                error={error['newPassword']}
                                value={form['newPassword']}
                                onChange={handleChange}
                            />
                            <FormField
                                name="returnNewPassword"
                                type={PASSWORD_TYPE}
                                value={form['returnNewPassword']}
                                error={error['returnNewPassword']}
                                onChange={handleChange}
                                onBlur={handleComparePassword}
                            />
                            <Confirm
                                dimmer="blurring"
                                open={confirmation.open}
                                onCancel={confirmation.onCancel}
                                cancelButton={t('cancelConfirm')}
                                confirmButton={t('Yes')}
                                onConfirm={confirmation.onConfirm}
                                content={confirmation.content}
                            />
                        </Form>
                    ),
                },
            ];
        },
        [form, error, confirmation],
    );

    return (
        <CardLayout
            title={t('profile_settings')}
            actionsFooter={getActionsFooter}
            actionsHeader={getActionsHeader}
            content={getContent}
            onClose={handleClose}
            isMobile={props.isMobile}
        />
    );
};

export default Profile;
