import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Redirect, Switch, useLocation, useHistory, Route,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { List, Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import qs from 'qs';

import {
    getListRequest,
    totalCountSelector,
    modesFromGridSelector,
    listSelector,
    progressSelector,
    gridModesCountersSelector,
} from '../ducks/gridList';
import { homePageSelector } from '../ducks/profile';
import { scrollSelector, saveScroll } from '../ducks/scroll';
import { getRepresentationsRequest } from '../ducks/representations';
import CustomGridCard from '../containers/customGrid/card';
import CustomDictionaryCard from '../containers/customDictionary/card';
import { SHIPPINGS_GRID } from '../constants/grids';
import { PAGE_SIZE } from '../constants/settings';
import {
    GRID_LIST_LINK,
    GRID_CARD_LINK,
    DICTIONARY_LIST_LINK,
    DICTIONARY_CARD_LINK,
    DICTIONARY_NEW_LINK,
    LOGIN_LINK,
    REG_LINK,
    TC_FORM_NO_LOGGED_LINK,
    PROFILE_LINK,
    LANDING_LINK,
} from '../router/links';
import UnauthRoute from '../router/unauthRoute';
import PrivateRoute from '../router/privateRoute';
import PrivateRegRoute from '../router/privateRegRoute';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import { MobileFilters, MobileCard, Tabs } from './components';
import LoginMobile from './pages/login';
import DictionaryPage from './pages/dictionary';
import Profile from '../containers/users/profile';
import { PROFILE_PERMISSION } from '../constants/permissions';
import RedirectToLanding from "../router/redirectToLanding";

const columns = [
    {
        kind: 'select',
        name: 'fromWhere',
        source: 'zones',
        title: 'fromWhere',
        icon: 'map marker alternate',
        params: { isForm: true },
    },
    {
        kind: 'select',
        name: 'whereTo',
        source: 'zones',
        title: 'whereTo',
        icon: 'map marker alternate',
        params: { isForm: true },
    },
    {
        kind: 'calendar',
        name: 'firstLoadingDateTime',
        title: 'firstLoadingDateTimeFilter',
        icon: 'calendar alternate outline',
        getSelectedItems(dates = []) {
            return () => dates;
        },
    },
    {
        kind: 'select',
        name: 'vehicleTypeId',
        source: 'list',
        title: 'vehicleTypeId',
        icon: 'map marker alternate',
        params: {
            name: 'vehicleTypeId',
            entities: 'shippings',
            isFilter: true,
        },
    },
];

const StyledText = styled.div`
    text-align: center;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    color: #dbdbdb;
    padding: 10px 0;
`;

const urlParams = new URLSearchParams();

const HomePage = ({
    list, getList, getRepresentations, saveScroll, totalCount, modes, initialScroll,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const isInitial = useRef(true);

    const urlGetParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

    const defaultFilters = {
        search: urlGetParams.search || '',
        ...(urlGetParams.fromWhere ? { fromWhere: urlGetParams.fromWhere?.split('|') } : {}),
        ...(urlGetParams.whereTo ? { whereTo: urlGetParams.whereTo?.split('|') } : {}),
        ...(urlGetParams.vehicleTypeId ? { vehicleTypeId: urlGetParams.vehicleTypeId?.split('|') } : {}),
        ...(urlGetParams.firstLoadingDateTime ? { firstLoadingDateTime: urlGetParams.firstLoadingDateTime?.split('|') } : {}),
    };

    const [activeIndex, setActiveIndex] = useState(modes[0].mode);
    const [filters, setFilters] = useState(defaultFilters);
    const loading = useSelector(progressSelector);
    const counters = useSelector(gridModesCountersSelector);

    const loadMore = ({ isConcat, isReload, tab = activeIndex, newFilters = filters }) => {
        getRepresentations({ key: SHIPPINGS_GRID });

        getList({
            name: SHIPPINGS_GRID,
            filter: {
                filter: {
                    search: newFilters.search,
                    fromWhere: newFilters.fromWhere,
                    whereTo: newFilters.whereTo,
                    vehicleTypeId: newFilters.vehicleTypeId?.join('|'),
                    firstLoadingDateTime: newFilters.firstLoadingDateTime?.join('-'),
                    gridMode: `${tab}`,
                },
                take: isInitial.current && list.length ? list.length : PAGE_SIZE,
                skip: isReload ? 0 : list?.length,
                sort: {},
            },
            isConcat,
            isReload,
        });

        isInitial.current = false;
    };

    const hasMore = list.length < totalCount;

    const [bottomRef] = useInfiniteScroll({
        loadMore: () => loadMore({ isConcat: !isInitial.current, isReload: isInitial.current  }),
        hasMore,
        dataLength: list.length,
        initialScroll,
        saveScroll,
        name: SHIPPINGS_GRID
    });

    const updateUrlParams = (newData) => {
        Object.entries(newData).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
                urlParams.set(key, Array.isArray(value) ? value.join('|') : value);
            } else {
                urlParams.delete(key);
            }
        });

        history.push({ search: urlParams.toString() });
    };

    const handleChangeFilters = (newFilters) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...newFilters,
        }));

        updateUrlParams(newFilters);

        loadMore({ isReload: true, isConcat: false, newFilters });
    };

    const handleResetFilters = (newFilters) => {
        setFilters(() => ({
            ...newFilters,
        }));

        updateUrlParams({
            search: '',
            fromWhere: undefined,
            whereTo:undefined,
            vehicleTypeId: undefined,
            firstLoadingDateTime:undefined,
        });

        loadMore({ isReload: true, isConcat: false, newFilters });
    };

    const handleClick = (id) => {
        history.push(GRID_CARD_LINK.replace(':name', SHIPPINGS_GRID).replace(':id', id));
    };

    const handleChangeTab = (data) => {
        setActiveIndex(data.activeIndex);
        updateUrlParams({ mode: data.activeIndex });
        loadMore({ tab: data.activeIndex, isReload: true, isConcat: false });
    };

    useEffect(() => {
        const currentUrlParams = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
        let mode = parseInt(currentUrlParams.mode);

        if (!modes.find((item) => item.mode === mode)) {
            mode = activeIndex;

            currentUrlParams.mode = mode;
        }

        if (mode !== activeIndex) {
            updateUrlParams(currentUrlParams);
            setActiveIndex(mode);
            loadMore({ isReload: true, isConcat: false, tab: mode });
        } else if (isInitial.current) {
            updateUrlParams(currentUrlParams);
            loadMore({ isReload: true, isConcat: false, tab: mode });
        }
    }, [location]);

    return (
        <Tabs onTabChange={handleChangeTab} counters={counters} activeIndex={activeIndex}>
            <MobileFilters
                defaultFilters={filters}
                columns={columns}
                source={SHIPPINGS_GRID}
                onChange={handleChangeFilters}
                onReset={handleResetFilters}
            />

            <Dimmer active={loading} inverted style={{ position: 'fixed' }}>
                <Loader size="medium" />
            </Dimmer>

            <List style={{ margin: '2px 14px' }}>
                {list.map((item, index) => (
                    <List.Item key={item.id} id={item.id} onClick={() => handleClick(item.id)}>
                        <MobileCard indexRow={index} data={item} />
                    </List.Item>
                ))}


                {(loading || hasMore) && (
                    <List.Item>
                        <StyledText ref={bottomRef}>{t('data_loading')}...</StyledText>
                    </List.Item>
                )}
            </List>
        </Tabs>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getList: (params) => {
        dispatch(getListRequest(params));
    },
    getRepresentations: params => {
        dispatch(getRepresentationsRequest(params));
    },
    saveScroll: params => {
        dispatch(saveScroll(params));
    },
});

const mapStateToProps = (state, ownProps) => {
    const { match = {}, nameReport } = ownProps;
    const { params = {} } = match;
    const { name = nameReport || '' } = params;

    return {
        list: listSelector(state),
        modes: modesFromGridSelector(state, name),
        totalCount: totalCountSelector(state),
        initialScroll: scrollSelector(state, name),
    };
};

const WrappedHomePage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomePage);

const MainRouteMobile = () => {
    const location = useLocation();
    const history = useHistory();

    const homePage = useSelector((state) => homePageSelector(state));

    useEffect(
        () => {
            const { pathname } = location;

            if (pathname === '/grid' && homePage) {
                history.push(homePage);
            }
        },
        [homePage],
    );

    return (
        <Switch>
            <UnauthRoute exact path="/" component={RedirectToLanding} />

            <PrivateRoute exact path={GRID_LIST_LINK} component={WrappedHomePage} />
            <PrivateRoute exact path={GRID_CARD_LINK} component={(props) => <CustomGridCard {...props} isMobile />} />

            <PrivateRegRoute exact path={TC_FORM_NO_LOGGED_LINK} component={(props) => <CustomGridCard {...props} isMobile />} />

            <Route exact path={DICTIONARY_LIST_LINK} component={DictionaryPage} />
            <PrivateRoute exact path={DICTIONARY_NEW_LINK} component={CustomDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_CARD_LINK} component={CustomDictionaryCard} />

            <PrivateRoute exact path={PROFILE_LINK} permission={PROFILE_PERMISSION} component={(props) => <Profile {...props} isMobile />} />

            <Route exact path={REG_LINK} component={() => <LoginMobile openSignUpForm/>} />
            <UnauthRoute exact path={LOGIN_LINK} component={() => <LoginMobile/>} />

            <PrivateRoute exact path="*" component={() => <Redirect to={homePage} />} />
        </Switch>
    );
};

export default MainRouteMobile;
